import React, { useMemo, useState } from 'react';
import { DidomiCarousel, DidomiCarouselContentSlide } from '@didomi/ui-atoms-react';
import { useEnvironment, useGTMEvent, useSPARouter } from '@didomi/utility-react';
import { useMediaQuery, useSPAAssetsUrl } from '@didomi/helpers-react/hooks';
import { tw } from '@didomi/ui-foundation';
import { usePropertyLastReport } from '@hooks';
import { ReportReadyModal } from '@modals';
import { AgnostikReport, ReportStatus } from '@types';

const REPORT_END_STATUSES = [ReportStatus.ANALYZED, ReportStatus.FAILURE];

interface LoadingComplianceReportPageProps {
  refetchLastComplianceReport: () => void;
}

export const LoadingComplianceReportPage = ({ refetchLastComplianceReport }: LoadingComplianceReportPageProps) => {
  const [isLastReportFinished, setIsLastReportEnabled] = useState(false);

  usePropertyLastReport({
    refetchInterval: 10_000,
    enabled: !isLastReportFinished,
    // TODO: Migration: Change AgnostikReport type to ComplianceReport type after the migration.
    onSuccess: (report: AgnostikReport) => {
      if (REPORT_END_STATUSES.includes(report?.status)) {
        setIsLastReportEnabled(true);
      }
    },
  });

  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/compliance-report');
  const { assetsBaseUrl } = useEnvironment();
  const { navigateTo } = useSPARouter();
  const isMobileSmall = useMediaQuery('(max-width: 375px)');
  const isMobileMedium = useMediaQuery('(min-width: 375px) and (max-width: 576px)');
  const isTabletSmall = useMediaQuery('(min-width: 576px) and (max-width: 768px)');
  const isTabletMedium = useMediaQuery('(min-width: 768px) and (max-width: 922px)');

  useGTMEvent('product-version', {
    product: 'self-service',
    page_path: 'privacy-tips',
  });

  /* istanbul ignore next - we are not covering this cases in unit tests */
  const imageSourceSize = useMemo(() => {
    if (isMobileSmall) return 'Mobile 375';
    if (isMobileMedium) return 'Mobile 576';
    if (isTabletSmall) return 'Tablet 768';
    if (isTabletMedium) return 'Tablet 922';
    return 'Desktop';
  }, [isMobileSmall, isMobileMedium, isTabletSmall, isTabletMedium]);

  const handleGoToComplianceReportClick = () => {
    // refetch the last report after a new report is created will trigger the re-render of the page
    refetchLastComplianceReport();
  };

  return (
    <div className="w-full min-h-full bg-light-blue relative overflow-auto">
      <div className="absolute inset-0">
        <div className="relative w-full h-full overflow-hidden">
          <img src={`${assetsBaseUrl}/global/sphere-pink-blur.png`} className="z-10 absolute bottom-[-150px] left-[-250px]" alt="" />
        </div>
      </div>
      <div
        className={tw(
          'relative z-10 bg-primary-pink-0 w-full min-h-[140px] flex items-center flex-col md:flex-row! px-s py-s md:py-0! shadow-banner border-b-1 border-solid border-neutral-gray-3',
          {
            'hidden!': isLastReportFinished,
          },
        )}
      >
        <div className="flex flex-col md:flex-row! w-full items-center justify-center md:justify-end! md:border-r-1! border-neutral-gray-3 border-solid px-m">
          <object type="image/svg+xml" data={`${ASSETS_URL}/assets/illustrations/loadingAnimationLoop.svg`} className="mr-0 mb-xxs md:mr-xs! md:mb-0!" width={64}>
            Loading image
          </object>
          <div className="flex-col text-center md:text-left! md:max-w-[238px]">
            <div className="font-serif font-bold text-[20px] text-primary-blue-6">Your compliance report is being generated</div>
            <span className="text-tooltip text-primary-blue-4 font-semibold">Usually less than 15 minutes</span>
          </div>
        </div>

        <div className="px-m w-full flex flex-col items-center md:items-start! text-center md:text-left!">
          <span className="text-body-small text-primary-blue-6 mt-xxs max-w-[382px]">
            In the meantime, take a look at some privacy best practices below, or discover our{' '}
            <button className="font-semibold underline" type="button" onClick={() => navigateTo('/consent-notices')}>
              Consent Management Platform
            </button>{' '}
            and come back in a few minutes!
          </span>
        </div>
      </div>
      <div className="w-full p-s lg:p-xl! z-10 relative lg:mb-0! flex flex-col items-center">
        <div className="w-full min-h-[590px] h-[590px] max-w-[1105px]">
          <DidomiCarousel className="rounded-[8px] shadow-privacy-tips" data-tracking="self-service-privacy-tips">
            <DidomiCarouselContentSlide
              contentHeader="DIDOMI ACADEMY"
              contentTag="GDPR"
              className="children:(last-child:(bg-neutral-gray-1!))"
              mediaSrc={`${ASSETS_URL}/assets/illustrations/Carousel images/${imageSourceSize}/${imageSourceSize}-1.png`}
            >
              <div slot="content" className="bg-white!">
                <h1 className="font-serif font-semibold leading-[32px] text-[24px] text-primary-blue-6">
                  Under the GDPR, consent for the collection of data must be <span className="text-primary-blue-4">obtained through an opt-in option</span>
                </h1>
                <div className="text-body-normal font-sans text-primary-blue-6 mt-xs">
                  <strong>The collection of consent should be explicit.</strong> You can&apos;t collect consent by default or by other implicit means like scrolling or navigation
                  on the website.
                </div>
              </div>
            </DidomiCarouselContentSlide>
            <DidomiCarouselContentSlide
              contentHeader="DIDOMI ACADEMY"
              contentTag="GDPR"
              className="children:(last-child:(bg-neutral-gray-1!))"
              mediaSrc={`${ASSETS_URL}/assets/illustrations/Carousel images/${imageSourceSize}/${imageSourceSize}-2.png`}
            >
              <div slot="content">
                <h1 className="font-serif font-semibold leading-[32px] text-[24px] text-primary-blue-6">
                  In many countries, consent for cookies has to be <span className="text-primary-blue-4">renewed after a certain time</span>
                </h1>
                <div className="text-body-normal font-sans text-primary-blue-6 mt-xs">
                  Consent is only valid for a time, even if you don&apos;t implement changes to your privacy policies. Refer to your local data authority guidelines to get accurate
                  information on the timeline.
                </div>
              </div>
            </DidomiCarouselContentSlide>
            <DidomiCarouselContentSlide
              contentHeader="DIDOMI ACADEMY"
              contentTag="GDPR"
              className="children:(last-child:(bg-neutral-gray-1!))"
              mediaSrc={`${ASSETS_URL}/assets/illustrations/Carousel images/${imageSourceSize}/${imageSourceSize}-3.png`}
            >
              <div slot="content">
                <h1 className="font-serif font-semibold leading-[32px] text-[24px] text-primary-blue-6">
                  <span className="text-primary-blue-4">Analytical Cookies</span> ≠ Technical Cookies
                </h1>
                <div className="text-body-normal font-sans text-primary-blue-6 mt-xs">
                  <strong>Analytical cookies are not considered technical cookies</strong>, which are essential for the proper functioning of a website. You need to collect consent
                  for the analytical cookies, not for the technical ones.
                </div>
              </div>
            </DidomiCarouselContentSlide>
            <DidomiCarouselContentSlide
              contentHeader="DIDOMI ACADEMY"
              contentTag="CCPA"
              className="children:(last-child:(bg-neutral-gray-1!))"
              mediaSrc={`${ASSETS_URL}/assets/illustrations/Carousel images/${imageSourceSize}/${imageSourceSize}-4.png`}
            >
              <div slot="content">
                <h1 className="font-serif font-semibold leading-[32px] text-[24px] text-primary-blue-6">
                  Under the CCPA, providing an opt-out option is mandatory if the <span className="text-primary-blue-4">personal information is sold</span>
                </h1>
                <div className="text-body-normal font-sans text-primary-blue-6 mt-xxs">
                  <strong className="underline">Do Not Sell</strong>
                  <br />
                  <span className="text-body-small">
                    Users must be able to choose to opt-out, and it should be easily visible and accessible on your website. You&apos;ll also have to authenticate consent to
                    collect personal information from minors between 13-16.
                  </span>
                </div>
              </div>
            </DidomiCarouselContentSlide>
            <DidomiCarouselContentSlide
              contentHeader="DIDOMI ACADEMY"
              contentTag="CCPA"
              className="children:(last-child:(bg-neutral-gray-1!))"
              mediaSrc={`${ASSETS_URL}/assets/illustrations/Carousel images/${imageSourceSize}/${imageSourceSize}-5.png`}
            >
              <div slot="content">
                <h1 className="font-serif font-semibold leading-[32px] text-[24px] text-primary-blue-6">
                  The privacy policy needs to record the personal <span className="text-primary-blue-4">information stored for the past 12 months</span>
                </h1>
                <div className="text-body-normal font-sans text-primary-blue-6 mt-xxxs">
                  In case a consumer wants to access their personal data, organizations should be able to provide information on the data gathered in the past 12 months.
                </div>
              </div>
            </DidomiCarouselContentSlide>
          </DidomiCarousel>
        </div>
      </div>
      <ReportReadyModal isOpen={isLastReportFinished} onSeeReportClicked={handleGoToComplianceReportClick} />
    </div>
  );
};
