import { useMemo, useState } from 'react';
import { PaginationSize } from '@types';

const DEFAULT_VALUE_PAGE = 1;
const DEFAULT_VALUE_LIMIT = 10;

export type usePaginationOptions = {
  defaultPage?: number;
  defaultSize?: PaginationSize;
};

export const useLocalPagination = (items: any[], defaultOptions: usePaginationOptions = { defaultPage: DEFAULT_VALUE_PAGE, defaultSize: DEFAULT_VALUE_LIMIT }) => {
  const [page, setPage] = useState<number>(defaultOptions.defaultPage);
  const [size, setSize] = useState<PaginationSize>(defaultOptions.defaultSize);

  const paginatedItems = useMemo(() => {
    const start = (page - 1) * size;
    const end = start + size;
    return items.slice(start, end);
  }, [items, page, size]);

  const updateSize = (newSizeEvent: CustomEvent<{ page: number; start: number; limit: PaginationSize }>) => {
    setSize(newSizeEvent.detail.limit);
    setPage(DEFAULT_VALUE_PAGE);
  };

  const updatePage = (newPageEvent: CustomEvent<{ page: number; start: number; limit: PaginationSize }>) => {
    setPage(newPageEvent.detail.page);
  };

  return {
    items: paginatedItems,
    page,
    size,
    updateSize,
    updatePage,
  };
};
