import React from 'react';
import { connect, theme } from '@didomi/ui-foundation';
import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from '@didomi/helpers-react';
import { setupCypress } from '@mocks';
import { App } from '@pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const ASSET_URL = CONFIG.standalone ? 'http://localhost:9000' : new URL(DIDOMI_SPA_IMPORTS_MAP['@didomi-spa/compliance-report']).origin;

// Setup Twind for compliance-report only
connect('.compliance-report-root', {
  theme: {
    extend: {
      ...theme.extend,
      minWidth: {
        ...theme.extend.minWith,
        'report-criteria-card': '248px',
      },
      maxWidth: {
        ...theme.extend.maxWith,
        'side-push': '452px',
        'report-panel': '1572px',
        'page-size': '1616px',
      },
      colors: {
        ...theme.extend.colors,
        preview: {
          gray: '#526E7A',
          violet: '#5B4D92',
          mustard: '#B99D53',
          salmon: '#E58C71',
        },
      },
      boxShadow: {
        ...theme.extend.boxShadow,
        banner: '0px 0px 6px rgba(0, 0, 0, 0.1)',
        'privacy-tips':
          '0px 2.767256498336792px 2.2138051986694336px 0px #00000006, 0px 6.650102138519287px 5.32008171081543px 0px #00000008, 0px 12.521552085876465px 10.017241477966309px 0px #0000000A, 0px 22.3363094329834px 17.869047164916992px 0px #0000000C, 0px 100px 80px 0px #00000014',
        'report-card': '0px 0px 10px rgba(0, 0, 0, 0.08)',
        'report-score':
          '0px 100px 80px rgba(0, 0, 0, 0.08), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916)',
        'book-demo-form': '0 20px 40px rgb(221 232 237 / 50%)',
      },
      gridTemplateColumns: {
        ...theme.extend.gridTemplateColumns,
        'report-info': 'minmax(0, 126px) minmax(0, 1fr)',
      },
      backgroundImage: {
        ...theme.extend.backgroundImage,
        'notice-preview': `url(${ASSET_URL}/assets/illustrations/background-notice.svg)`,
      },
    },
  },
});

if (window['Cypress']) {
  setupCypress();
} else {
  // makeServer({ scenario: 'failure' });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  },
});

const Root = () => {
  return (
    <UtilityProvider utility={utility}>
      <ErrorBoundary
        sentryConfig={{
          sentryDsn: CONFIG.sentryDsn,
          environment: CONFIG.environment,
          release: CONFIG.commitSha,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<App />}></Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </UtilityProvider>
  );
};

export { Root };
