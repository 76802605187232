import axios, { AxiosInstance } from 'axios';
import { addAxiosResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/axios/axios-response-interceptors';
import { useAuthMethods } from '@didomi/utility-react';

const axiosWithLogoutInstance = axios.create();
let axiosWithLogoutInterceptors: AxiosInstance;

// TODO: FIXME: write tests before merging
export const useAxiosWithLogOut = () => {
  const { logout } = useAuthMethods();
  if (axiosWithLogoutInterceptors) {
    return axiosWithLogoutInterceptors;
  }

  axiosWithLogoutInterceptors = addAxiosResponseInterceptors(axiosWithLogoutInstance, { onExpiredToken: logout, onForbidden: logout });
  return axiosWithLogoutInterceptors;
};
