export class AgnostikReport {
  id: string;
  organization_id: string;
  property_id: string;
  status: ReportStatus | null;
  status_messages?: Record<string, unknown> | null;
  scraping_sessions?: { id: string; status: CollectStatus }[] | Record<string, any> | null;
  score: number;
  trackers_count: number;
  vendors_count: number;
  unmatched_slds_count: number;
  tcfv2_vendors_count: number;
  gatp_vendors_count: number;
  custom_vendors_count: number;
  details_json?: Record<string, any> | null = null;
  details_base64?: string;
  created?: string;
  modified?: string;
  is_cron_requested: boolean;
  is_user_requested: boolean;
}

/* eslint-disable no-unused-vars */
export enum ReportStatus {
  READY = 'ready',
  RUNNING = 'running',
  FAILURE = 'failure',
  SUCCESS = 'success',
  ANALYZED = 'analyzed',
}
/* eslint-enable no-unused-vars */

/* eslint-disable no-unused-vars */
export enum CollectStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  COMPLETED = 'COMPLETED',
}
/* eslint-enable no-unused-vars */
