import React from 'react';
import { useMediaQuery } from '@didomi/helpers-react';
import { DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButton } from '@didomi/ui-atoms-react';

type ReportReadyModalProps = {
  isOpen: boolean;
  onSeeReportClicked: () => void;
  onChange?: (e: CustomEvent) => void;
};

export const ReportReadyModal = ({ isOpen, onSeeReportClicked, onChange, ...props }: ReportReadyModalProps) => {
  const isSmallLayout = useMediaQuery('(max-width: 576px');

  return (
    <DidomiModal isOpen={isOpen} permanent="true" onOpenChange={onChange} variant={isSmallLayout ? 'slim' : 'normal'} {...props}>
      <DidomiModalHeader modalSubTitle="Good news!" modalTitle="Your compliance report is ready" illustrationName="access-my-dashboard"></DidomiModalHeader>

      <DidomiModalActions>
        <div>
          <DidomiButton onClick={() => onSeeReportClicked()}>See my compliance report</DidomiButton>
        </div>
      </DidomiModalActions>
    </DidomiModal>
  );
};
