export const displayDaysFormat = (receivedSeconds: number): string => {
  if (!receivedSeconds || receivedSeconds < 0) {
    return 'Session';
  }

  if (receivedSeconds < 86400) {
    const hours = Math.floor(receivedSeconds / 3600);
    const minutes = Math.floor((receivedSeconds - hours * 3600) / 100);
    const seconds = receivedSeconds - minutes * 100 - hours * 3600;

    let res = [];
    if (hours > 0) {
      res.push(hours + ` hour${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
      res.push(minutes + ` minute${minutes > 1 ? 's' : ''}`);
    }
    if (seconds > 0) {
      res.push(seconds + ` second${seconds > 1 ? 's' : ''}`);
    }

    return res.join(', ');
  } else {
    const receivedDays = receivedSeconds / 86400;

    const years = Math.floor(receivedDays / 365);
    const months = Math.floor((receivedDays - years * 365) / 30.4);
    const days = Math.round(receivedDays - years * 365 - months * 30.4);

    let res = [];
    if (years > 0) {
      res.push(years + ` year${years > 1 ? 's' : ''}`);
    }
    if (months > 0) {
      res.push(months + ` month${months > 1 ? 's' : ''}`);
    }
    if (days > 0) {
      res.push(days + ` day${days > 1 ? 's' : ''}`);
    }

    return res.join(', ');
  }
};
