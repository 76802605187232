import React, { useMemo, useRef, useState } from 'react';
import { useGTM, useGTMEvent, useSPARouter } from '@didomi/utility-react';
import { tw } from '@didomi/ui-foundation';
import { DidomiButton, DidomiCtaPopIn, DidomiErrorState, DidomiIconButton, DidomiSkeleton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useCreateReport, usePropertyLastReport } from '@hooks';
import { BookADemoNotice, PromoteConsentNotice, ReportCriteria, ReportCriteriaStatus, ReportItemsFound, ReportScore } from '@components';
import { RelaunchReportModal } from '@modals';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import { useIntersectionObserver } from 'usehooks-ts';
import { ComplianceReport } from '@types';
import { convertFromLegacyComplianceReport } from '@utils';

export const ComplianceReportPage = () => {
  const [displayRelaunchReportModal, setDisplayRelaunchReportModal] = useState(false);
  const { pushEventToDataLayer } = useGTM();
  const { navigateTo } = useSPARouter();
  const { data: latestComplianceReportLegacy, isLoading, isError, refetch } = usePropertyLastReport();
  const { mutate: createReport, isLoading: isCreatingReport } = useCreateReport({
    onSuccess: () => {
      // Note: refetch the last report after a new report is created will trigger the re-render of the page
      refetch();
    },
  });
  const isSmallLayout = useMediaQuery('(max-width: 768px)');
  const isLargeLayout = useMediaQuery('(min-width: 1200px)');
  const isMediumLayout = !isSmallLayout && !isLargeLayout;
  const promoSpaceRef = useRef<HTMLDivElement | null>(null);
  const promoRefIObserver = useIntersectionObserver(promoSpaceRef, {});
  const promoRefIsVisible = !!promoRefIObserver?.isIntersecting;

  // TODO: Migration: Remove me when using the new GET endpoint (AWS)
  //  so that the right data structure will be already served by the API.
  const latestComplianceReport: ComplianceReport = useMemo(() => convertFromLegacyComplianceReport(latestComplianceReportLegacy), [latestComplianceReportLegacy]);

  useGTMEvent('product-version', {
    product: 'self-service',
    page_path: 'dashboard',
  });

  const showRelaunchReportModal = () => {
    setDisplayRelaunchReportModal(true);
  };

  /* istanbul ignore next - We don't care to test this, it's just a safety net for the modal to close correctly */
  const handleRelaunchReportModalChange = (e: CustomEvent) => {
    if (displayRelaunchReportModal && !e.detail) {
      setDisplayRelaunchReportModal(e.detail);
    }
  };

  const handleRelaunchReport = () => {
    pushEventToDataLayer('self-service-relaunch-report');
    createReport();
  };

  return (
    <>
      <DidomiSkeleton
        id="report-content"
        className="w-full min-h-full flex justify-center bg-light-blue p-s xl:p-l! text-primary-blue-6"
        variant="layout"
        isLoading={isLoading || !latestComplianceReport}
      >
        {!promoRefIsVisible && !isLoading && isSmallLayout && (
          <aside className="fixed z-10 right-0 bottom-[48px]" data-testid="cta-pop-in">
            <DidomiCtaPopIn
              variant="compact"
              lead-illustration="CTA_gauge"
              description="Bring your compliance to the next level with Didomi!"
              descriptionBlockWidth="112px"
              button-text="Discover"
              onBtnClicked={/*istanbul ignore next */ () => navigateTo('/consent-notices')}
            />
          </aside>
        )}
        {!promoRefIsVisible && !isLoading && isMediumLayout && (
          <PromoteConsentNotice className="fixed bottom-0 left-0 right-0 z-10 min-h-[168px]" onActionClicked={/*istanbul ignore next */ () => navigateTo('/consent-notices')} />
        )}
        <div className="flex-1 w-full max-w-page-size text-left">
          <div className="flex flex-wrap-reverse items-center">
            <div className="flex-1 flex flex-col text-center md:text-left! items-center md:items-start!">
              <h1 className="h1-catchline">Dashboard</h1>
              <div className="font-sans text-body-normal md:min-w-[520px]!">
                <span className="inline-block">Welcome to your dashboard. </span>{' '}
                <span className="inline-block">Let&apos;s take a look at your compliance score and find ways to improve it together!</span>
              </div>
            </div>
          </div>
          <div className="mt-m w-full flex flex-col xl:flex-row!">
            <div className="flex-1 max-w-report-panel">
              {isError ? (
                <DidomiErrorState
                  className="min-h-[720px] bg-white"
                  illustration-name="content-loading-error"
                  action-name="Retry"
                  action-icon="reset"
                  onActionClick={/* istanbul ignore next */ () => refetch()}
                >
                  <div slot="title">It seems to be an error</div>
                  <div slot="description">Your compliance report cannot be generated</div>
                </DidomiErrorState>
              ) : (
                <div className="w-full bg-white rounded-lg flex flex-col py-xs md:py-l!">
                  <div
                    className={tw('flex justify-between items-center text-primary-blue-5 text-body-big font-semibold mx-xs md:mx-xl! mb-l', {
                      'ml-m': isSmallLayout,
                    })}
                  >
                    <div className="text-field-label text-neutral-gray-0 font-semibold flex items-center">
                      <div className="mr-xxxs" data-skeleton={isLoading}>
                        Running date
                      </div>
                      <div data-skeleton={isLoading}>{new Date(latestComplianceReport?.updated_at).toLocaleString()}</div>
                    </div>
                    <div>
                      {isSmallLayout ? (
                        <DidomiTooltip content="Relaunch the report" placement="top">
                          <DidomiIconButton
                            data-tracking="self-service-relaunch-compliance-report"
                            data-skeleton
                            className="ml-s"
                            variant="rounded-border"
                            icon="reset"
                            onClick={showRelaunchReportModal}
                          />
                        </DidomiTooltip>
                      ) : (
                        <DidomiButton
                          data-tracking="self-service-relaunch-compliance-report"
                          data-skeleton
                          className="ml-s"
                          variant="secondary"
                          size="small"
                          icon-left="reset"
                          onClick={showRelaunchReportModal}
                        >
                          Relaunch
                        </DidomiButton>
                      )}
                    </div>
                  </div>

                  <div className="px-xs md:px-xl!">
                    <ReportScore website={latestComplianceReport?.property?.website} score={latestComplianceReport?.score} isLoading={isLoading} />
                    <ReportCriteriaStatus isLoading={isLoading} isSmallLayout={isSmallLayout} complianceReport={latestComplianceReport} />
                    <ReportCriteria isLoading={isLoading} complianceReport={latestComplianceReport} />
                  </div>
                </div>
              )}
              <ReportItemsFound complianceReport={latestComplianceReport} isLoading={isLoading} />
            </div>
            {isLargeLayout && (
              <div className="max-w-[300px] ml-xl 2xl:max-w-[452px]! sticky top-xs h-full">
                <PromoteConsentNotice className="2xl:p-xl!" vertical isLoading={isLoading} onActionClicked={/*istanbul ignore next */ () => navigateTo('/consent-notices')} />
                <BookADemoNotice className="w-full mt-s 2xl:p-xl!" vertical isLoading={isLoading} onActionClicked={() => navigateTo('/consent-notices/book-demo')} />
              </div>
            )}
          </div>

          <div className="w-full flex flex-col-reverse md:flex-row mt-s">
            {!isLargeLayout && (
              <PromoteConsentNotice
                vertical
                className="flex-1 mt-s md:mt-0! md:mr-s! h-[352px]! justify-center"
                onActionClicked={/*istanbul ignore next */ () => navigateTo('/consent-notices')}
              />
            )}
            <div ref={promoSpaceRef}></div>
            {!isLargeLayout && <BookADemoNotice vertical className="flex-1 h-[352px]! justify-center" onActionClicked={() => navigateTo('/consent-notices/book-demo')} />}
          </div>
        </div>
      </DidomiSkeleton>
      {!isLoading && (
        <>
          <RelaunchReportModal
            data-testid="relaunch-report-modal"
            complianceReport={latestComplianceReport}
            isLoading={isCreatingReport}
            isOpen={displayRelaunchReportModal}
            onCancel={() => setDisplayRelaunchReportModal(false)}
            onChange={handleRelaunchReportModalChange}
            onConfirm={handleRelaunchReport}
          />
        </>
      )}
    </>
  );
};
