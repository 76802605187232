import { useAuthToken } from '@didomi/utility-react';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ScrapingSession } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';
import { useOrganizationProperty } from './useOrganizationProperty.hook';

/**
 * Triggers a re-launch of compliance report by creating a new report
 * */
export const useCreateReport = (options?: UseMutationOptions<AxiosResponse<ScrapingSession>, AxiosError>) => {
  const token = useAuthToken();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { data: organizationProperty } = useOrganizationProperty();

  return useMutation(async () => {
    const requestBody = {
      property_id: organizationProperty?.id,
    };

    return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}reports/compliances/reports`, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        organization_id: organizationProperty?.organization_id,
      },
    });
  }, options);
};
