/**
 * Remove duplicates from an array if its provided items' keys
 * are identical.
 * Example:
 *  Input:
 *  [
 *      {name: 'John', age: 25, level: 10},
 *      {name: 'Paul', age: 27, level: 9},
 *      {name: 'John', age: 25, level: 10},
 *      {name: 'John', age: 25, level: 11}
 *  ], ['name', 'age', 'level']
 *  Output:
 *  [{name: 'John', age: 25, level: 10}, {name: 'Paul', age: 27, level: 9}, {name: 'John', age: 25, level: 11}]
 * @param array
 * @param keys
 */
export const deduplicateArrayByObjectKeys = <T extends Record<string, any>>(array: T[], keys: (keyof T)[]): T[] => {
  if (!keys.length) {
    return array;
  }

  return Array.from(new Map(array.map((item: T) => [keys.map((key: string) => item[key]).join(''), item])).values());
};
