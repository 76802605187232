import React from 'react';
import {
  DidomiEmptyState,
  DidomiIcon,
  DidomiPaginator,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { tw } from '@didomi/ui-foundation';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import { useLocalPagination } from '@hooks';
import { displayDaysFormat } from '@utils';
import { Tracker } from '@types';

type ReportItemsThirdPartyTrackersProps = {
  trackers: Tracker[];
};

/**
 * Component for showing the third party trackers tab content
 *
 * @component
 * @param {ReportItemsThirdPartyTrackersProps} props
 * @example
 * return (
 *   <ReportItemsThirdPartyCookies trackers={thirdPartyTrackers} />
 * )
 */
export const ReportItemsThirdPartyCookies = ({ trackers }: ReportItemsThirdPartyTrackersProps) => {
  const isSmallScreen = useMediaQuery('(max-width: 960px)');

  const { items: paginatedTrackers, page: page, size: size, updateSize: updateSize, updatePage: updatePage } = useLocalPagination(trackers || []);

  return (
    <div className="p-xxxs md:p-xs!">
      <div className="text-body-small px-s text-center">
        <span className="font-semibold">3rd party cookies:</span> Cookies set by vendors on their own domains when they are embedded on a page of the monitored website.
      </div>
      {!trackers?.length && (
        <DidomiEmptyState className="mt-m" illustration-name="no-matches-found">
          We didn’t find any 3rd party cookies on your website
        </DidomiEmptyState>
      )}
      {!!trackers?.length && (
        <>
          <div>
            {isSmallScreen ? (
              paginatedTrackers.map((tracker: Tracker, idx: number) => (
                <div key={'first-cookie-' + idx} className="m-xxxs md:mx-s! my-xs p-xs bg-neutral-gray-1 rounded-lg border-1 border-solid border-neutral-gray-3 flex flex-col">
                  <div className="grid grid-cols-report-info grid-rows-4 font-sans">
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Name</div>
                    <div className="h-6 text-[11px] truncate flex items-center">{tracker.name}</div>
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Path access</div>
                    <DidomiTooltip
                      content={tracker.page_url}
                      onlyIfTruncated
                      className="flex items-center w-full"
                      placement="top"
                      maxWidth={window.screen.width - 10}
                      forceBreakLine
                    >
                      <div className="h-6 text-[11px] truncate w-full">{tracker.page_url}</div>
                    </DidomiTooltip>
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Vendor</div>
                    <div className="h-6 text-[11px] truncate flex items-center">{tracker.vendor_name || tracker.sld}</div>
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Cookie Lifetime</div>
                    <div className="h-6 text-[11px] truncate flex items-center">
                      {tracker.violates_expiration_rule && <DidomiIcon className="-ml-[6px] scale-50" name="warning" />}
                      <div>{displayDaysFormat(tracker.max_lifetime_seconds)}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <DidomiTable fixed-layout className="mt-m" min-width="1">
                <DidomiTableHeading className="bg-neutral-gray-1">
                  <DidomiTableHeaderRow>
                    <DidomiTableTh>Name</DidomiTableTh>
                    <DidomiTableTh>Path access</DidomiTableTh>
                    <DidomiTableTh>Vendor</DidomiTableTh>
                    <DidomiTableTh>Cookie Lifetime</DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>
                <DidomiTableBody>
                  {paginatedTrackers.map((tracker: Tracker, idx: number) => (
                    <DidomiTableRow key={'first-cookie-' + idx}>
                      <DidomiTableTd className="flex items-center">{tracker.name}</DidomiTableTd>
                      <DidomiTableTd>
                        <DidomiTooltip content={tracker.page_url} onlyIfTruncated className="flex items-center w-full" maxWidth={500} hidden={false} forceBreakLine>
                          <div className="w-full truncate">{tracker.page_url}</div>
                        </DidomiTooltip>
                      </DidomiTableTd>
                      <DidomiTableTd className="flex items-center">{tracker.vendor_name || tracker.sld}</DidomiTableTd>
                      <DidomiTableTd className="flex items-center">
                        <div className="flex items-center">
                          {tracker.violates_expiration_rule && <DidomiIcon className="-ml-[6px] scale-50" name="warning" />}
                          <div>{displayDaysFormat(tracker.max_lifetime_seconds)}</div>
                        </div>
                      </DidomiTableTd>
                    </DidomiTableRow>
                  ))}
                </DidomiTableBody>
              </DidomiTable>
            )}
          </div>

          <div className={tw('w-full mt-s flex justify-center', { 'justify-end': !isSmallScreen })}>
            <DidomiPaginator
              variant={isSmallScreen ? 'compact' : 'normal'}
              page={page}
              size={size}
              itemCount={trackers?.length}
              onPageSizeChange={updateSize}
              onPageChange={updatePage}
            />
          </div>
        </>
      )}
    </div>
  );
};
