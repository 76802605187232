import React, { MouseEvent } from 'react';
import { DidomiButton, DidomiPromotionalPush } from '@didomi/ui-atoms-react';
import { useEnvironment } from '@didomi/utility-react';

type BookADemoNoticeProps = {
  vertical?: boolean;
  isLoading?: boolean;
  className?: string;
  onActionClicked?: (event: MouseEvent<HTMLDidomiButtonElement, globalThis.MouseEvent>) => void;
};

/**
 * Renders promotional push that let's you book a demo (aka talk to an expert)
 *
 * @component
 * @param {BookADemoNoticeProps} props
 * @example
 * return (
 *   <BookADemoNotice
 *      vertical={false}
 *      isLoading={isLoading}
 *   />
 * )
 */
export const BookADemoNotice = ({ className = '', vertical = false, isLoading = false, onActionClicked }: BookADemoNoticeProps) => {
  const { assetsBaseUrl } = useEnvironment();

  return (
    <DidomiPromotionalPush
      data-skeleton={isLoading}
      className={className}
      borderStyle={vertical ? 'normal' : 'plain'}
      vertical={vertical}
      data-testid="notice-preview-push"
      style={{ height: 'max-content' }}
    >
      <img width={142} height={142} slot="img" loading="lazy" src={`${assetsBaseUrl}/global/Multi-expert.png`} alt="" />

      <h3 slot="title" className="h3 font-bold">
        Do you have any questions?
      </h3>
      <DidomiButton data-testid="book-a-demo-cta" data-tracking="self-service-cmp-talk-to-expert" disabled={isLoading} size="small" slot="cta" onClick={onActionClicked}>
        Talk to an expert
      </DidomiButton>
    </DidomiPromotionalPush>
  );
};
