import React from 'react';
import { ReportCriteriaCard } from '@components';
import { ComplianceReport, Report } from '@types';

type ReportCriteriaProps = {
  complianceReport: ComplianceReport;
  isLoading: boolean;
};

/**
 * Renders the 6 corresponding criteria cards for the compliance report
 *
 * @component
 * @param {ReportCriteriaProps} props
 * @example
 * return (
 *   <ReportCriteriaCard
 *      isLoading={isLoading}
 *      complianceReport={complianceReport}
 *   />
 * )
 */
export const ReportCriteria = ({ complianceReport, isLoading = false }: ReportCriteriaProps) => {
  return (
    <div className="grid grid-cols-1 gap-xs md:gap-s! lg:grid-cols-2!">
      <ReportCriteriaCard
        id="info-policy"
        isLoading={isLoading}
        passed={complianceReport?.privacy?.has_user_consent_information}
        passedText="You provide information about purposes and vendors on the homepage"
        failedText="You do not provide information about purposes and vendors on the homepage"
        passedInformation="You correctly users about their rights regarding your use of cookies and other purposes and vendors."
        failedInformation="You are not correctly informing users. You should add information about your use of cookies and other purposes and vendors."
      />
      <ReportCriteriaCard
        id="enforce-choices"
        isLoading={isLoading}
        passed={complianceReport?.privacy?.has_user_consent_choice}
        passedText="You enforce users' choices with a Consent Management Platform (CMP)"
        failedText="You do not enforce users' choices with a Consent Management Platform (CMP)"
        passedInformation="You are correctly enforcing users' choices and sharing them with 3rd-party vendors through a CMP."
        failedInformation="You must enforce users' choices and share them with 3rd-party vendors through a CMP."
      />
      <ReportCriteriaCard
        id="cookie-lifetime"
        isLoading={isLoading}
        passed={complianceReport?.privacy?.has_short_cookies_lifetime}
        passedText="The lifetime of your cookies is correct"
        failedText="The lifetime of your cookies is too long"
        passedInformation="No cookie is set with a life time over 13 months."
        failedInformation="Some cookies are set with a life time of over 13 months. You can either ask the vendor to reduce the lifetime of the cookie, or ask them how to delete it completely."
      />
      <ReportCriteriaCard
        id="has-policy"
        isLoading={isLoading}
        passed={complianceReport?.privacy?.has_privacy_policy}
        passedText="You have a privacy policy"
        failedText="You do not have a privacy policy"
        passedInformation="There is a privacy policy available on your website."
        failedInformation="There is no privacy policy available on your website."
      />
      <ReportCriteriaCard
        id="policy-find"
        isLoading={isLoading}
        passed={complianceReport?.privacy?.has_privacy_policy_all_pages}
        passedText="Your privacy policy is easy to find"
        failedText="Your privacy policy is hard to find"
        passedInformation="There is a link to your privacy policy on all pages of your website."
        failedInformation="Some pages of your website do not have a link to your privacy policy."
      />
      <ReportCriteriaCard
        id="allows-optout"
        isLoading={isLoading}
        passed={complianceReport?.privacy?.has_privacy_policy_opt_out}
        passedText="You allow users to opt out"
        failedText="You do not allow users to opt out"
        passedInformation="Your privacy policy or your privacy center provides information on data processing and users' rights, as well as options to opt out."
        failedInformation="Users should be able to update their consent preferences easily."
      />
    </div>
  );
};
