import React from 'react';
import {
  DidomiEmptyState,
  DidomiPaginator,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { tw } from '@didomi/ui-foundation';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import { useLocalPagination } from '@hooks';
import { Request } from '@types';

type ReportItemsTagsProps = {
  requests: Request[];
};

/**
 * Component for showing the first party cookie tab content
 *
 * @component
 * @param {ReportItemsTagsProps} props
 * @example
 * return (
 *   <ReportItemsTags requests={requests} />
 * )
 */
export const ReportItemsTags = ({ requests }: ReportItemsTagsProps) => {
  const isSmallScreen = useMediaQuery('(max-width: 960px)');

  const { items: requestsPaginated, page: requestsPage, size: requestsSize, updateSize: updateRequestSize, updatePage: updateRequestPage } = useLocalPagination(requests);

  return (
    <div className="p-xxxs md:p-xs!">
      <div className="text-body-small px-s text-center">
        <span className="font-semibold">TAGS:</span> HTTP requests sent to vendors by any of the pages of the monitored website.
      </div>
      {!requests?.length && (
        <DidomiEmptyState className="mt-m" illustration-name="no-matches-found">
          We didn’t find any tags on your website
        </DidomiEmptyState>
      )}
      {!!requests?.length && (
        <>
          <div>
            {isSmallScreen ? (
              requestsPaginated.map((request: Request, idx: number) => (
                <div key={'first-cookie-' + idx} className="m-xxxs md:mx-s! my-xs p-xs bg-neutral-gray-1 rounded-lg border-1 border-solid border-neutral-gray-3 flex flex-col">
                  <div className="grid grid-cols-report-info grid-rows-4 font-sans">
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Name</div>
                    <DidomiTooltip content={request.url} onlyIfTruncated className="flex items-center w-full" placement="top" maxWidth={window.screen.width - 10} forceBreakLine>
                      <div className="h-6 text-[11px] truncate w-full">{request.url}</div>
                    </DidomiTooltip>
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Vendor</div>
                    <div className="h-6 text-[11px] truncate flex items-center">{request.vendor_name || 'Website cookies'}</div>
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Tag type</div>
                    <div className="h-6 text-[11px] truncate flex items-center">{request.type}</div>
                    <div className="h-6 flex items-center uppercase text-primary-blue-6 font-semibold text-[12px]">Initiator</div>
                    <div className="h-6 text-[11px] truncate flex items-center">{request.initiator_vendor_name || 'Session'}</div>
                  </div>
                </div>
              ))
            ) : (
              <DidomiTable fixed-layout className="mt-m" min-width="1">
                <DidomiTableHeading className="bg-neutral-gray-1">
                  <DidomiTableHeaderRow>
                    <DidomiTableTh>Name</DidomiTableTh>
                    <DidomiTableTh>Vendor</DidomiTableTh>
                    <DidomiTableTh>Tag type</DidomiTableTh>
                    <DidomiTableTh>Initiator</DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>
                <DidomiTableBody>
                  {requestsPaginated.map((request: Request, idx: number) => (
                    <DidomiTableRow key={'first-cookie-' + idx}>
                      <DidomiTableTd>
                        <DidomiTooltip content={request.url} onlyIfTruncated className="flex items-center w-full" maxWidth={500} hidden={false} forceBreakLine>
                          <div className="w-full truncate">{request.url}</div>
                        </DidomiTooltip>
                      </DidomiTableTd>
                      <DidomiTableTd className="flex items-center">{request.vendor_name || 'Website cookies'}</DidomiTableTd>
                      <DidomiTableTd className="flex items-center">{request.type}</DidomiTableTd>
                      <DidomiTableTd className="flex items-center">{request.initiator_vendor_name || 'Session'}</DidomiTableTd>
                    </DidomiTableRow>
                  ))}
                </DidomiTableBody>
              </DidomiTable>
            )}
          </div>
          <div className={tw('w-full mt-s flex justify-center', { 'justify-end': !isSmallScreen })}>
            <DidomiPaginator
              variant={isSmallScreen ? 'compact' : 'normal'}
              page={requestsPage}
              size={requestsSize}
              itemCount={requests.length}
              onPageSizeChange={updateRequestSize}
              onPageChange={updateRequestPage}
            />
          </div>
        </>
      )}
    </div>
  );
};
