import React, { useMemo } from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { PieChart } from 'react-minimal-pie-chart';

const scoreResultMessages = {
  veryLow: {
    color: 'url(#score-very-low)',
    message: 'Hmm, we can certainly do better with some basic adjustments',
  },
  low: {
    color: 'url(#score-low)',
    message: 'Hmm, we can certainly do better with some basic adjustments',
  },
  medium: {
    color: 'url(#score-medium)',
    message: 'Not bad! There is still room for improvement on some privacy principles',
  },
  high: {
    color: 'url(#score-high)',
    message: 'Great! Your site already follows basic privacy principles',
  },
};

type ReportScoreProps = {
  score: number;
  website: string;
  isLoading?: boolean;
};

/**
 * Displays the report score pie chart and the corresponding message
 *
 * @component
 * @param {ReportScoreProps} props
 * @example
 * return (
 *   <ReportScore score={80} />
 * )
 */
export const ReportScore = ({ score, website, isLoading = false }: ReportScoreProps) => {
  const scoreResults = useMemo(() => {
    switch (true) {
      case score <= 30:
        return scoreResultMessages.veryLow;
      case score <= 60:
        return scoreResultMessages.low;
      case score <= 90:
        return scoreResultMessages.medium;
      default:
        return scoreResultMessages.high;
    }
  }, [score]);

  const scoreData = [{ value: score || 0, color: scoreResults?.color || '' }];

  return (
    <div className="flex flex-col items-center mt-s md:(flex-row! mt-0!)">
      <PieChart
        data={scoreData}
        lineWidth={16}
        totalValue={100}
        rounded
        background="#EFF7FB"
        className="max-w-[160px] shadow-report-score rounded-full border border-solid border-white border-2"
        startAngle={270}
        label={() => (isLoading ? '00%' : score + '%')}
        labelStyle={{
          fontSize: '30px',
          fontFamily: '"IBM Plex Sans", IBMPlexSans',
          fontWeight: 700,
          fill: isLoading ? '#EFF7FB' : '#1D4153',
        }}
        labelPosition={0}
      >
        <linearGradient id="score-very-low" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#C03C14" />
          <stop offset="100%" stopColor="#C24C28" />
        </linearGradient>
        <linearGradient id="score-low" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#D66A49" />
          <stop offset="100%" stopColor="#EAA85B" />
        </linearGradient>
        <linearGradient id="score-medium" gradientTransform="rotate(-30)">
          <stop offset="0%" stopColor="#B4D25F" />
          <stop offset="100%" stopColor="#EAA85B" />
        </linearGradient>
        <linearGradient id="score-high" gradientTransform="rotate(-30)">
          <stop offset="0%" stopColor="#10C767" />
          <stop offset="100%" stopColor="#B4D25F" />
        </linearGradient>
      </PieChart>
      <div className="flex flex-col justify-center items-center ml-0 mt-s text-center md:(ml-[40px]! mt-0! justify-start! items-start! text-left!)">
        <div data-skeleton className="text-body-small font-semibold text-primary-blue-4 font-sans">
          Your compliance score
        </div>
        <div data-skeleton className="h3 font-bold text-primary-blue-6 mt-xxs max-w-[420px]">
          {scoreResults.message}
        </div>
        <div className="flex items-center mt-s">
          <DidomiIcon className="mr-xxs" name="website" />
          <span data-skeleton className="text-body-small font-semibold text-primary-blue-5">
            {isLoading ? 'Loading website' : website}
          </span>
        </div>
      </div>
    </div>
  );
};
