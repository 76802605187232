import React, { MouseEvent } from 'react';
import { DidomiButton, DidomiPromotionalPush } from '@didomi/ui-atoms-react';
import { useEnvironment } from '@didomi/utility-react';

type PromoteConsentNoticeProps = {
  vertical?: boolean;
  isLoading?: boolean;
  className?: string;
  onActionClicked?: (event: MouseEvent<HTMLDidomiButtonElement, globalThis.MouseEvent>) => void;
};

/**
 * Renders promotional push that let's you learn about the notice preview
 *
 * @component
 * @param {PromoteConsentNoticeProps} props
 * @example
 * return (
 *   <PromoteConsentNotice
 *      vertical={false}
 *      isLoading={isLoading}
 *   />
 * )
 */
export const PromoteConsentNotice = ({ className = '', vertical = false, isLoading = false, onActionClicked }: PromoteConsentNoticeProps) => {
  const { assetsBaseUrl } = useEnvironment();

  return (
    <DidomiPromotionalPush
      data-skeleton={isLoading}
      className={className}
      borderStyle={vertical ? 'normal' : 'plain'}
      variant="light"
      vertical={vertical}
      data-testid="notice-preview-push"
      style={{ height: 'max-content' }}
    >
      <img width={172} height={102} slot="img" loading="lazy" src={`${assetsBaseUrl}/global/CMP-score-promo.png`} alt="" />

      <h3 slot="title" className="h3 font-bold text-white">
        Take compliance to the next level with Didomi&apos;s Consent Notices!
      </h3>
      <DidomiButton
        data-testid="learn-more-cta"
        data-tracking="self-service-learn-more-from-compliance-report"
        disabled={isLoading}
        slot="cta"
        size="small"
        variant="secondary-light"
        onClick={onActionClicked}
      >
        Learn More
      </DidomiButton>
    </DidomiPromotionalPush>
  );
};
