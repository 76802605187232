import React from 'react';
import { useCreateReport } from '@hooks';
import { DidomiErrorState } from '@didomi/ui-atoms-react';
import { useSPARouter } from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react/hooks';
import { PromoteConsentNotice } from '@components';

interface ComplianceReportErrorPageProps {
  refetchLastComplianceReport: () => void;
}

export const ComplianceReportErrorPage = ({ refetchLastComplianceReport }: ComplianceReportErrorPageProps) => {
  const isLargeLayout = useMediaQuery('(min-width: 1200px)');
  const { navigateTo } = useSPARouter();
  const { mutate: createReport } = useCreateReport({
    onSuccess: () => {
      // refetch the last report after a new report is created will trigger the re-render of the page
      refetchLastComplianceReport();
    },
  });

  return (
    <div className="w-full min-h-full flex justify-center bg-light-blue p-s lg:p-l! text-primary-blue-6">
      <div className="flex-1 w-full max-w-page-size text-left">
        <h1 className="h1-catchline">Dashboard</h1>
        <span className="font-sans text-body-normal">Welcome to your dashboard. Let&apos;s take a look at your compliance score and find ways to improve it together!</span>
        <div className="mt-m w-full flex flex-col xl:flex-row!">
          <div className="flex-1 max-w-report-panel">
            <DidomiErrorState
              className="min-h-[720px] bg-white"
              illustration-name="content-loading-error"
              action-name="Retry"
              action-icon="reset"
              onActionClick={() => createReport()}
            >
              <div slot="title">There seems to be a problem.</div>
              <div slot="description">
                <div>
                  <div>An error occurred during the scan of your website.</div>
                  <div>Your compliance report cannot be generated.</div>
                </div>
              </div>
            </DidomiErrorState>
          </div>

          {isLargeLayout ? (
            <PromoteConsentNotice className="max-w-[300px] ml-m" vertical onActionClicked={/*istanbul ignore next */ () => navigateTo('/consent-notices')} />
          ) : (
            <PromoteConsentNotice className="mt-s" vertical onActionClicked={/*istanbul ignore next */ () => navigateTo('/consent-notices')} />
          )}
        </div>
      </div>
    </div>
  );
};
