import { ComplianceReport, ReportStatus } from '@types';

export const mediumScoreComplianceReport: ComplianceReport = {
  id: '913c3a66000b6777b9d081dacc0e6e794982',
  organization_id: 'QdADLr6M',
  property_id: 'AKMrpHrT',
  status: ReportStatus.ANALYZED,
  score: 50,
  property: {
    id: 'AKMrpHrT',
    name: 'didomi-test-property-medium-score',
    website: 'https://www.didomi.io/',
  },
  privacy: {
    has_user_consent_information: true,
    has_user_consent_choice: true,
    has_short_cookies_lifetime: true,
    has_privacy_policy: true,
    has_privacy_policy_all_pages: false,
    has_privacy_policy_opt_out: true,
  },
  trackers: [
    {
      name: 'bb',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'business.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'business.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1764,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1765,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'hsforms.com',
      sld: 'hsforms.com',
      initiator_url_host: 'forms-na1.hsforms.com',
      initiator_url_sld: 'hsforms.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1765,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'hsforms.net',
      sld: 'hsforms.net',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1792,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'hubspot.com',
      sld: 'hubspot.com',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1792,
      is_third_party: true,
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'www.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1763,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cfruid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'business.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'business.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cfruid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cfruid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'www.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '_cfuvid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'hsforms.com',
      sld: 'hsforms.com',
      initiator_url_host: 'forms-na1.hsforms.com',
      initiator_url_sld: 'hsforms.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'www.google.com_/pagead/1p-user-list/',
      type: 'pixel',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'google.com',
      sld: 'google.com',
      initiator_url_host: 'www.google.com',
      initiator_url_sld: 'google.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
  ],
  requests: [
    {
      url: 'https://api.hubapi.com/hs-script-loader-public/v1/config/pixels-and-events/json?portalId=7560723',
      url_host: 'api.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://js.hsadspixel.net/fb.js:0:4331',
      initiator_url_sld: 'hsadspixel.net',
      initiator_url_host: 'js.hsadspixel.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://apis.google.com/js/client.js',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded:5:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://apis.google.com/js/googleapis.proxy.js?onload=startup',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url:
        'https://content-people-pa.googleapis.com/static/proxy.html?usegapi=1&jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.IKZeRvoAYNY.O%2Fam%3DAAAQ%2Fd%3D1%2Frs%3DAHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw%2Fm%3D__features__:12:0',
      initiator_url_sld: 'content-people-pa.googleapis.com',
      initiator_url_host: 'content-people-pa.googleapis.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://apis.google.com/_/scs/abc-static/_/js/k=gapi.lb.en.IKZeRvoAYN.O/m=client/rt=j/sv=1/d=1/ed=1/am=AAAQ/rs=AHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw/cb=gapi.loaded_0?le=scs',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://apis.google.com/js/client.js:19:322',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'apis.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/fce82293-691f-4c3a-ae32-f8b46e5ccfe4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/referral-program:1954:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/fed750da-4e08-442a-9097-e6fa0a0ebc2e.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform:2896:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/es/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/privacy-request:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/referral-program:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/resources:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/about:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/affiliate-program:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/client-stories:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/consent-management-platform:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/didomi-academy:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/es/:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/fr/:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/partner-program:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/privacy-request:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/referral-program:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/resources:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://app.hubspot.com/feedback-web-fetcher:0:0',
      initiator_url_sld: 'hubspot.com',
      initiator_url_host: 'app.hubspot.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347446&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347449&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347452&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347454&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347456&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347460&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347466&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347468&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347540&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347546&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347585&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347586&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347588&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347590&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347594&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347597&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233359478&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372316&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372318&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372319&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372320&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372325&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372358&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372359&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372361&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372363&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
  ],
  created_at: '2024-07-17T16:20:16.322Z',
  updated_at: '2024-07-17T16:29:03.806Z',
};
