import React from 'react';
import { DidomiHintbox } from '@didomi/ui-atoms-react';
import { ComplianceReport } from '@types';

type ReportCriteriaStatusProps = {
  complianceReport: ComplianceReport;
  isSmallLayout?: boolean;
  isLoading?: boolean;
};

/**
 * Displays the report summary of issues in a hint box format.
 *
 * @component
 * @param {ReportCriteriaStatusProps} props
 * @example
 * return (
 *   <ReportCriteriaStatus
 *      isLoading={isLoading}
 *      complianceReport={complianceReport}
 *   />
 * )
 */
export const ReportCriteriaStatus = ({ complianceReport, isSmallLayout = false, isLoading = false }: ReportCriteriaStatusProps) => {
  const informChoices = complianceReport?.privacy?.has_user_consent_information;
  const enforceChoices = complianceReport?.privacy?.has_user_consent_choice;
  const cookeLifetime = complianceReport?.privacy?.has_short_cookies_lifetime;

  const hasPrivacyPolicy = complianceReport?.privacy?.has_privacy_policy;
  const hasPrivacyPolicyInAllPages = complianceReport?.privacy?.has_privacy_policy_all_pages;
  const allowsOptOut = complianceReport?.privacy?.has_privacy_policy_opt_out;

  // We combine all the different results and filter only the negative values to get a count of the issues
  const issuesCount = [!informChoices, !enforceChoices, !cookeLifetime, !hasPrivacyPolicy, !hasPrivacyPolicyInAllPages, !allowsOptOut].filter(Boolean).length;

  const titleText =
    issuesCount > 0 ? `We found ${issuesCount} issue${issuesCount === 1 ? '' : 's'}.${isSmallLayout ? '' : ' Click on each item to learn more.'}` : 'We didn’t find any issue.';

  return <DidomiHintbox data-testid="report-hint" data-skeleton oneLine className="mt-l mb-s" titleText={titleText} iconName="warning-blue" />;
};
