import { ComplianceReport } from '@types';

interface Options {
  timeWindowMillis: number;
}

/**
 * Whether the given compliance report ran in the past time-window.
 * @param complianceReport
 * @param options
 */
export const hasReportRunInPastTimeWindow = (complianceReport: Partial<ComplianceReport>, options: Options) => {
  if (!complianceReport?.updated_at) {
    return false;
  }

  const complianceReportDateMillis: number = new Date(complianceReport.updated_at).getTime();
  const nextAvailableRunDateMillis = complianceReportDateMillis + options.timeWindowMillis;
  return Date.now() < nextAvailableRunDateMillis;
};
