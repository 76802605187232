import React, { useMemo } from 'react';
import { tw } from '@didomi/ui-foundation';
import { DidomiModal, DidomiModalHeader, DidomiLoader, DidomiModalActions, DidomiButton } from '@didomi/ui-atoms-react';
import { ComplianceReport } from '@types';
import { hasReportRunInPastTimeWindow } from '@utils';
import { ONE_HOUR_MILLIS } from '../../constants';

type RelaunchReportModalProps = {
  complianceReport: ComplianceReport;
  isLoading: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onChange: (e: CustomEvent) => void;
};

export const RelaunchReportModal = ({ complianceReport, isOpen, isLoading, onCancel, onConfirm, onChange }: RelaunchReportModalProps) => {
  const hasReportRunInPastHour = useMemo(() => hasReportRunInPastTimeWindow(complianceReport, { timeWindowMillis: ONE_HOUR_MILLIS }), [complianceReport]);

  return (
    <DidomiModal isOpen={isOpen} data-testid="relaunch-report-confirm-modal" onOpenChange={onChange}>
      <div
        className={tw({
          hidden: !isLoading,
        })}
      >
        <DidomiLoader className="mb-xs flex justify-center"></DidomiLoader>
        <DidomiModalHeader modal-title="We are relaunching your compliance report..." modal-sub-title="Please wait"></DidomiModalHeader>
      </div>

      <div
        className={tw({
          hidden: isLoading,
        })}
      >
        <DidomiModalHeader
          illustrationName="warning"
          modalTitle={hasReportRunInPastHour ? 'You can not run a new scan' : 'The compliance test will relaunch'}
          modalSubTitle="Please pay attention"
          modalDescription={
            hasReportRunInPastHour
              ? 'Your last report was launched less than an hour ago, you must wait at least an hour before launching a new one'
              : 'This action will delete your existing compliance report and launch a new one.'
          }
        ></DidomiModalHeader>
        <DidomiModalActions>
          <DidomiButton className="w-[135px]" variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton className="w-[135px]" onClick={onConfirm} disabled={hasReportRunInPastHour}>
            Relaunch
          </DidomiButton>
        </DidomiModalActions>
      </div>
    </DidomiModal>
  );
};
