import { ComplianceReport, ReportStatus } from '@types';

export const highScoreComplianceReport: ComplianceReport = {
  id: '913c3a66000b6777b9d081dacc0e6e794982',
  organization_id: 'QdADLr6M',
  property_id: 'AKMrpHrT',
  status: ReportStatus.ANALYZED,
  score: 90,
  property: {
    id: 'AKMrpHrT',
    name: 'didomi-test-property-high-score',
    website: 'https://www.didomi.io/',
  },
  privacy: {
    has_user_consent_information: true,
    has_user_consent_choice: true,
    has_short_cookies_lifetime: true,
    has_privacy_policy: true,
    has_privacy_policy_all_pages: true,
    has_privacy_policy_opt_out: true,
  },
  trackers: [
    {
      name: 'bb',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'business.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'business.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1764,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1765,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'hsforms.com',
      sld: 'hsforms.com',
      initiator_url_host: 'forms-na1.hsforms.com',
      initiator_url_sld: 'hsforms.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1765,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'hsforms.net',
      sld: 'hsforms.net',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1792,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'hubspot.com',
      sld: 'hubspot.com',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1792,
      is_third_party: true,
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cf_bm',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'www.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1763,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cfruid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'business.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'business.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cfruid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__cfruid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'www.didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      initiator_url_sld: 'didomi.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '_cfuvid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'hsforms.com',
      sld: 'hsforms.com',
      initiator_url_host: 'forms-na1.hsforms.com',
      initiator_url_sld: 'hsforms.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '_cfuvid',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'hubspot.com',
      sld: 'hubspot.com',
      initiator_url_host: 'no-cache.hubspot.com',
      initiator_url_sld: 'hubspot.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233381,
      is_third_party: true,
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      name: 'didomi_token',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'sdk.privacy-center.org',
      initiator_url_sld: 'privacy-center.org',
      violates_expiration_rule: false,
      max_lifetime_seconds: 15811200,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'didomi_token',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'sdk.privacy-center.org',
      initiator_url_sld: 'privacy-center.org',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'euconsent',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'sdk.privacy-center.org',
      initiator_url_sld: 'privacy-center.org',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233345,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'forms-na1.hsforms.com_/embed/v3/coun',
      type: 'pixel',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'forms-na1.hsforms.com',
      sld: 'hsforms.com',
      initiator_url_host: 'forms-na1.hsforms.com',
      initiator_url_sld: 'hsforms.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '_gcl_au',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'www.googletagmanager.com',
      initiator_url_sld: 'googletagmanager.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: 7776000,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      name: 'hasOwnProperty',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: '__hs_initial_opt_in',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.hs-banner.com',
      initiator_url_sld: 'hs-banner.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: 604800,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__hs_opt_out',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.hs-banner.com',
      initiator_url_sld: 'hs-banner.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: 15552000,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__hssc',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.hs-analytics.net',
      initiator_url_sld: 'hs-analytics.net',
      violates_expiration_rule: false,
      max_lifetime_seconds: 1800,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__hssrc',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.hs-analytics.net',
      initiator_url_sld: 'hs-analytics.net',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1721233359,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: '__hstc',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.hs-analytics.net',
      initiator_url_sld: 'hs-analytics.net',
      violates_expiration_rule: false,
      max_lifetime_seconds: 15552000,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'hubspotutk',
      type: 'cookie',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.hs-analytics.net',
      initiator_url_sld: 'hs-analytics.net',
      violates_expiration_rule: false,
      max_lifetime_seconds: 15552000,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'IDE',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'doubleclick.net',
      sld: 'doubleclick.net',
      initiator_url_host: 'googleads.g.doubleclick.net',
      initiator_url_sld: 'doubleclick.net',
      violates_expiration_rule: false,
      max_lifetime_seconds: 33695991,
      is_third_party: true,
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      name: 'LegoStorage',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'live-chat-local-toggle',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'js.usemessages.com',
      initiator_url_sld: 'usemessages.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'NID',
      type: 'cookie',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'google.com',
      sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      initiator_url_sld: 'google.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: 15811186,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'pageComponentErrorCount',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'pageRendered',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'perf.hsforms.com_/embed/v3/counters.',
      type: 'pixel',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'perf.hsforms.com',
      sld: 'hsforms.com',
      initiator_url_host: 'perf.hsforms.com',
      initiator_url_sld: 'hsforms.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'plausible_ignore',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'didomi.io',
      sld: 'didomi.io',
      initiator_url_host: 'plausible.io',
      initiator_url_sld: 'plausible.io',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: false,
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      name: 'rc::a',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'rc::c',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'seenPanels',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'SessionStorage',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'softLoaded',
      type: 'web_storage',
      page_url: 'https://www.didomi.io/consent-rate-benchmark',
      host: 'lookerstudio.google.com',
      sld: 'google.com',
      initiator_url_host: 'www.gstatic.com',
      initiator_url_sld: 'gstatic.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'test_cookie',
      type: 'cookie',
      page_url: 'https://www.didomi.io/',
      host: 'doubleclick.net',
      sld: 'doubleclick.net',
      initiator_url_host: 'googleads.g.doubleclick.net',
      initiator_url_sld: 'doubleclick.net',
      violates_expiration_rule: false,
      max_lifetime_seconds: 879,
      is_third_party: true,
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      name: 'track.hubspot.com_/__pto.gif',
      type: 'pixel',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'track.hubspot.com',
      sld: 'hubspot.com',
      initiator_url_host: 'track.hubspot.com',
      initiator_url_sld: 'hubspot.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      name: 'www.google.be_/pagead/1p-user-list/8',
      type: 'pixel',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'google.be',
      sld: 'google.be',
      initiator_url_host: 'www.google.be',
      initiator_url_sld: 'google.be',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      name: 'www.google.com_/pagead/1p-user-list/',
      type: 'pixel',
      page_url: 'https://www.didomi.io/us-webinar-series',
      host: 'google.com',
      sld: 'google.com',
      initiator_url_host: 'www.google.com',
      initiator_url_sld: 'google.com',
      violates_expiration_rule: false,
      max_lifetime_seconds: -1,
      is_third_party: true,
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
  ],
  requests: [
    {
      url: 'https://api.hubapi.com/hs-script-loader-public/v1/config/pixels-and-events/json?portalId=7560723',
      url_host: 'api.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://js.hsadspixel.net/fb.js:0:4331',
      initiator_url_sld: 'hsadspixel.net',
      initiator_url_host: 'js.hsadspixel.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://apis.google.com/js/client.js',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded:5:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://apis.google.com/js/googleapis.proxy.js?onload=startup',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url:
        'https://content-people-pa.googleapis.com/static/proxy.html?usegapi=1&jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.IKZeRvoAYNY.O%2Fam%3DAAAQ%2Fd%3D1%2Frs%3DAHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw%2Fm%3D__features__:12:0',
      initiator_url_sld: 'content-people-pa.googleapis.com',
      initiator_url_host: 'content-people-pa.googleapis.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://apis.google.com/_/scs/abc-static/_/js/k=gapi.lb.en.IKZeRvoAYN.O/m=client/rt=j/sv=1/d=1/ed=1/am=AAAQ/rs=AHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw/cb=gapi.loaded_0?le=scs',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://apis.google.com/js/client.js:19:322',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'apis.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://apis.google.com/_/scs/abc-static/_/js/k=gapi.lb.en.IKZeRvoAYNY.O/m=googleapis_proxy/rt=j/sv=1/d=1/ed=1/am=AAAQ/rs=AHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw/cb=gapi.loaded_0?le=scs',
      url_host: 'apis.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://apis.google.com/js/googleapis.proxy.js?onload=startup:19:322',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'apis.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://app.hubspot.com/api/cartographer/v1/ipl',
      url_host: 'app.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://app.hubspot.com/feedback-web-fetcher:0:3038',
      initiator_url_sld: 'hubspot.com',
      initiator_url_host: 'app.hubspot.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      url: 'https://app.hubspot.com/content-tools-menu/api/v1/tools-menu/has-permission-json?portalId=7560723',
      url_host: 'app.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/hsstatic/HubspotToolsMenu/static-1.349/js/index.js:0:3338',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://app.hubspot.com/feedback-web-fetcher',
      url_host: 'app.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hubspotfeedback.com/feedbackweb-new.js:0:19127',
      initiator_url_sld: 'hubspotfeedback.com',
      initiator_url_host: 'js.hubspotfeedback.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/ASSETS/components/forms/dropdown.svg',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/hs-fs/hub/7560723/hub_generated/module_assets/129538057227/1708710542793/module_129538057227_partner-program-hero.min.css:undefined:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/ASSETS/components/forms/dropdown.svg',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/hs-fs/hub/7560723/hub_generated/module_assets/130024738964/1720122470216/module_130024738964_form-step-hero-2023.min.css:undefined:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/about:3451:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:3639:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/affiliate-program:3889:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform:4118:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:3540:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3476:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/didomi-academy:3444:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/es/:3584:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/fr/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3565:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:3977:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:3576:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/partner-program:3775:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3700:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/privacy-request:3655:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/referral-program:3893:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/resources:3501:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/common/fonts/static/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3395:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/widgets/privacy-hub/2.0.0/privacy-hub-widget.js',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/hubfs/Development%20Files/widgets/privacy-hub/2.0.0/privacy-hub-widget-loader.js:319:20',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/widgets/privacy-hub/assets/Chevron.svg',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Development%20Files/widgets/privacy-hub/assets/Chevron.svg',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://business.didomi.io/hubfs/Development%20Files/widgets/privacy-hub/2.0.0/privacy-hub-widget.js:37:23',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'business.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/GLOBAL%20PRIVACY%20UX%20website/ABOUT%20US/Vdef%2016-9%20ST%20Didomi%20REBRANDING.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/about:3205:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/GLOBAL%20PRIVACY%20UX%20website/CLIENT%20STORIES/Video-thumbnail-XL-desktop-Mediahuis.webp',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/GLOBAL%20PRIVACY%20UX%20website/CLIENT%20STORIES/Video-thumbnail-XL-desktop-Orange.webp',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/GLOBAL%20PRIVACY%20UX%20website/CLIENT%20STORIES/Video-thumbnail-XL-desktop-SNCF.webp',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/GLOBAL%20PRIVACY%20UX%20website/CLIENT%20STORIES/Video-thumbnail-XL-desktop-Soge.webp',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/GLOBAL%20PRIVACY%20UX%20website/PRODUCT%20PAGE%20-%20CMP/Didimi-CMP-demo%20(1).mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform:3146:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/Privacy%20Request%20module_subtitled.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/privacy-request:2354:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/SG_English_Voice_Over_V2.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/social-suggested-images/Vef%2016_9%20%20Montage%20Didomi%20x%20Mediahuis.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/:2464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/social-suggested-images/Vef%2016_9%20%20Montage%20Didomi%20x%20Mediahuis.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:3412:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/social-suggested-images/Vef%2016_9%20%20Montage%20Didomi%20x%20Mediahuis.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/es/:2463:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/social-suggested-images/Vef%2016_9%20%20Montage%20Didomi%20x%20Mediahuis.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/fr/:2479:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/social-suggested-images/Vef%2016_9%20%20Montage%20Didomi%20x%20Mediahuis.mp4',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3166:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/website-2023/assets/icons/general/preferences.svg',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/hs-fs/hub/7560723/hub_generated/template_assets/119459658117/1703002744271/didomi-website-theme/css-new/main.min.css:undefined:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://business.didomi.io/hubfs/YESWETRUST/fonts/Authentic-Signature.ttf',
      url_host: 'business.didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/hs-fs/hub/7560723/hub_generated/template_assets/119459658117/1703002744271/didomi-website-theme/css-new/main.min.css:undefined:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/about:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/affiliate-program:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/client-stories:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/consent-management-platform:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:8:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/didomi-academy:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/es/:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/fr/:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/partner-program:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/preference-management-platform:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/privacy-request:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/referral-program:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/resources:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css',
      url_host: 'cdn.jsdelivr.net',
      url_sld: 'jsdelivr.net',
      initiator_url: 'https://www.didomi.io/us-webinar-series:6:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'jsdelivr-qcwdU2DY',
      vendor_name: 'jsdelivr',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://content-people-pa.googleapis.com/$discovery/rest?pp=0&fields=fields%5B%22kind%22%5D%2Cfields%5B%22name%22%5D%2Cfields%5B%22version%22%5D%2Cfields%5B%22rootUrl%22%5D%2Cfields%5B%22servicePath%22%5D%2Cfields%5B%22resources%22%5D%2Cfields%5B%22parame',
      url_host: 'content-people-pa.googleapis.com',
      url_sld: 'content-people-pa.googleapis.com',
      initiator_url:
        'https://apis.google.com/_/scs/abc-static/_/js/k=gapi.lb.en.IKZeRvoAYNY.O/m=googleapis_proxy/rt=j/sv=1/d=1/ed=1/am=AAAQ/rs=AHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw/cb=gapi.loaded_0?le=scs:182:241',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'apis.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://content-people-pa.googleapis.com/static/proxy.html?usegapi=1&jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.IKZeRvoAYNY.O%2Fam%3DAAAQ%2Fd%3D1%2Frs%3DAHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw%2Fm%3D__features__',
      url_host: 'content-people-pa.googleapis.com',
      url_sld: 'content-people-pa.googleapis.com',
      initiator_url:
        'https://apis.google.com/_/scs/abc-static/_/js/k=gapi.lb.en.IKZeRvoAYNY.O/m=client/rt=j/sv=1/d=1/ed=1/am=AAAQ/rs=AHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw/cb=gapi.loaded_0?le=scs:614:493',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'apis.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://content-sheets.googleapis.com/static/proxy.html?usegapi=1&jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.IKZeRvoAYNY.O%2Fam%3DAAAQ%2Fd%3D1%2Frs%3DAHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw%2Fm%3D__features__',
      url_host: 'content-sheets.googleapis.com',
      url_sld: 'content-sheets.googleapis.com',
      initiator_url:
        'https://apis.google.com/_/scs/abc-static/_/js/k=gapi.lb.en.IKZeRvoAYNY.O/m=client/rt=j/sv=1/d=1/ed=1/am=AAAQ/rs=AHpOoo-SMWTzMRJrTty6iE5dL_aWGYOnuw/cb=gapi.loaded_0?le=scs:614:493',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'apis.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fabout&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fadvanced-compliance-monitoring&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Faffiliate-program&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fclient-stories&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fconsent-management-platform%2Fconnected-tv&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fconsent-management-platform&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fconsent-rate-benchmark&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fdidomi-academy&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fes%2F&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fbanking-finance&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fhealthcare-pharma&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fmedia-publishers&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fpartner-program&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fpreference-management-platform&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fprivacy-request&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Freferral-program&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fresources&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2Fus-webinar-series&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://core.service.elfsight.com/p/boot/?page=https%3A%2F%2Fwww.didomi.io%2F&w=62ee2078-8a95-4b32-9aab-3e2464c08534',
      url_host: 'core.service.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:12807',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?canon=https%3A%2F%2Fwww.didomi.io%2F&pageId=63779586135&pid=7560723&sv=cta-embed-js-static-1.302&rdy=1&cos=1&df=t&pg=cf701828-184e-4d94-bc39-b30be9d89565&pg=367fea90-be96-440c-8386-e14b0d6d7d',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.10.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fpartner-program&hsutk=ab34f66',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.11.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fconsent-management-platform%2',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.1.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2F&hsutk=ab34f66562d23b837ef6c8a',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.12.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Faffiliate-program&hsutk=ab34f',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.13.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fconsent-management-platform&h',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.14.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fprivacy-request&hsutk=ab34f66',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.15.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fpreference-management-platfor',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.16.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fbanking-finance&hs',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.17.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fmedia-publishers&h',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.18.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fadvanced-compliance-monitorin',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.19.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fclient-stories&hsutk=ab34f665',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.20.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fhealthcare-pharma&',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.2.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Ffr%2F&hsutk=ab34f66562d23b837e',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.3.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fes%2F&hsutk=ab34f66562d23b837e',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.4.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fresources&hsutk=ab34f66562d23b',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.5.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fconsent-rate-benchmark&hsutk=a',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.6.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fdidomi-academy&hsutk=ab34f6656',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.7.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Freferral-program&hsutk=ab34f66',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.8.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fabout&hsutk=ab34f66562d23b837e',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://cta-service-cms2.hubspot.com/ctas/v2/public/cs/cta-json?__hssc=47810744.9.1721233347443&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&canon=https%3A%2F%2Fwww.didomi.io%2Fus-webinar-series&hsutk=ab34f6',
      url_host: 'cta-service-cms2.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18116',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/about:3451:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:3639:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/affiliate-program:3889:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform:4118:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:3540:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3476:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/didomi-academy:3444:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/es/:3584:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/fr/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3565:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:3977:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:3576:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/partner-program:3775:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3700:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/privacy-request:3655:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/referral-program:3893:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/resources:3501:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://didomi.io/hubfs/Development%20Files/common/fonts/variable/Montserrat-VariableFont_wght.ttf',
      url_host: 'didomi.io',
      url_sld: 'didomi.io',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3395:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fabout&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fabout',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fadvanced-compliance-monitoring&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fadvanced-co',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Faffiliate-program&pageUrl=https%3A%2F%2Fwww.didomi.io%2Faffiliate-program',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fclient-stories&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fclient-stories',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fconsent-management-platform%2Fconnected-tv&pageUrl=https%3A%2F%2Fwww.didomi.io%2',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fconsent-management-platform&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fconsent-manage',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fconsent-rate-benchmark&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fconsent-rate-benchm',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fdidomi-academy&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fdidomi-academy',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fes%2F&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fes%2F',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Ffr%2F&pageUrl=https%3A%2F%2Fwww.didomi.io%2Ffr%2F',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fbanking-finance&pageUrl=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fbank',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fhealthcare-pharma&pageUrl=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fhe',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fmedia-publishers&pageUrl=https%3A%2F%2Fwww.didomi.io%2Findustry%2Fmed',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2F&pageUrl=https%3A%2F%2Fwww.didomi.io%2F',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fpartner-program&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fpartner-program',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fpreference-management-platform&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fpreference-',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fprivacy-request&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fprivacy-request',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Freferral-program&pageUrl=https%3A%2F%2Fwww.didomi.io%2Freferral-program',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fresources&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fresources',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://feedback.hubapi.com/feedback/public/v1/web-config?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&bundleVersion=1.20378¤tUrl=https%3A%2F%2Fwww.didomi.io%2Fus-webinar-series&pageUrl=https%3A%2F%2Fwww.didomi.io%2Fus-webinar-series',
      url_host: 'feedback.hubapi.com',
      url_sld: 'hubapi.com',
      initiator_url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js:0:12411',
      initiator_url_sld: 'hsappstatic.net',
      initiator_url_host: 'static.hsappstatic.net',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://fonts.googleapis.com/css?family=Droid+Sans:400italic,700italic,400,700,500|Syncopate:400italic,700italic,400,700,500|Impact:400italic,700italic,400,700|Ubuntu:400italic,700italic,400,700|Ubuntu+Mono:400italic,700italic,400,700|Open+Sans:400italic,',
      url_host: 'fonts.googleapis.com',
      url_sld: 'fonts.googleapis.com',
      initiator_url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded:0:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://fonts.googleapis.com/css?family=Montserrat:400,b,i',
      url_host: 'fonts.googleapis.com',
      url_sld: 'fonts.googleapis.com',
      initiator_url: 'https://static.elfsight.com/apps/g2crowd-reviews/stable/b14844927d36ceb2233e3ccb69aceaa295649d90/app/g2CrowdReviews.js:64:295651',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Extended',
      url_host: 'fonts.googleapis.com',
      url_sld: 'fonts.googleapis.com',
      initiator_url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded:0:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2',
      url_host: 'fonts.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url: 'https://fonts.googleapis.com/css?family=Montserrat:400,b,i:undefined:0',
      initiator_url_sld: 'fonts.googleapis.com',
      initiator_url_host: 'fonts.googleapis.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://fonts.gstatic.com/s/productsans/v19/pxiDypQkot1TnFhsFMOfGShVF9eO.woff2',
      url_host: 'fonts.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url:
        'https://fonts.googleapis.com/css?family=Droid+Sans:400italic,700italic,400,700,500|Syncopate:400italic,700italic,400,700,500|Impact:400italic,700italic,400,700|Ubuntu:400italic,700italic,400,700|Ubuntu+Mono:400italic,700italic,400,700|Open+Sans:400italic,',
      initiator_url_sld: 'fonts.googleapis.com',
      initiator_url_host: 'fonts.googleapis.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4.woff2',
      url_host: 'fonts.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url:
        'https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LcE_6MUAAAAACM2aL4qbFG8PNDIIl4krUNCLmXE&co=aHR0cHM6Ly9sb29rZXJzdHVkaW8uZ29vZ2xlLmNvbTo0NDM.&hl=en&v=rKbTvxTxwcw5VqzrtN-ICwWt&size=invisible&cb=gc6gehnfk521:183:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'www.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2',
      url_host: 'fonts.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url:
        'https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LcE_6MUAAAAACM2aL4qbFG8PNDIIl4krUNCLmXE&co=aHR0cHM6Ly9sb29rZXJzdHVkaW8uZ29vZ2xlLmNvbTo0NDM.&hl=en&v=rKbTvxTxwcw5VqzrtN-ICwWt&size=invisible&cb=gc6gehnfk521:183:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'www.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2',
      url_host: 'fonts.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url:
        'https://fonts.googleapis.com/css?family=Droid+Sans:400italic,700italic,400,700,500|Syncopate:400italic,700italic,400,700,500|Impact:400italic,700italic,400,700|Ubuntu:400italic,700italic,400,700|Ubuntu+Mono:400italic,700italic,400,700|Open+Sans:400italic,',
      initiator_url_sld: 'fonts.googleapis.com',
      initiator_url_host: 'fonts.googleapis.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&contentId=63779586135¤tUrl=https%3A%2F%2Fwww.didomi.io%2F',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.10.1721233347443&contentId=785536',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.11.1721233347443&contentId=115900',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.12.1721233347443&contentId=428243',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.13.1721233347443&contentId=154892',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.14.1721233347443&contentId=796860',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.15.1721233347443&contentId=115900',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.16.1721233347443&contentId=636982',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.17.1721233347443&contentId=137036',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.18.1721233347443&contentId=137036',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.19.1721233347443&contentId=115900',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.20.1721233347443&contentId=115900',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.21.1721233347443&contentId=131497',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.2.1721233347443&contentId=6377958',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.3.1721233347443&contentId=3805396',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.4.1721233347443&contentId=3808134',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.5.1721233347443&contentId=3821318',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.6.1721233347443&contentId=1560444',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.7.1721233347443&contentId=1611026',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.8.1721233347443&contentId=1475741',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms.hubspot.com/lead-flows-config/v1/config/json?portalId=7560723&utk=ab34f66562d23b837ef6c8a4944b5378&__hstc=47810744.ab34f66562d23b837ef6c8a4944b5378.1721233347442.1721233347442.1721233347442.1&__hssc=47810744.9.1721233347443&contentId=1370647',
      url_host: 'forms.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://js.hsleadflows.net/leadflows.js:0:258812',
      initiator_url_sld: 'hsleadflows.net',
      initiator_url_host: 'js.hsleadflows.net',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/es/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/about:3451:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3476:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:3444:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/fr/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3565:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3700:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/privacy-request:3655:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/referral-program:3893:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/resources:3501:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3395:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-DEFINITION_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/_hcms/forms/v2.js:0:434866',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/es/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/about:3451:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3476:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:3444:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/fr/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3565:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/partner-program:3775:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3700:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/privacy-request:3655:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/referral-program:3893:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/resources:3501:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3395:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://forms-na1.hsforms.com/embed/v3/counters.gif?key=forms-embed-v2-RENDER_SUCCESS&count=1',
      url_host: 'forms-na1.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/_hcms/forms/v2.js:0:434866',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233359683&cv=11&fst=1721233359683&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233371960&cv=11&fst=1721233371960&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550&l=dataLayer&cx=c:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233389296&cv=11&fst=1721233389296&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233405808&cv=11&fst=1721233405808&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233421795&cv=11&fst=1721233421795&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233440622&cv=11&fst=1721233440622&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233456479&cv=11&fst=1721233456479&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233472145&cv=11&fst=1721233472145&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233489225&cv=11&fst=1721233489225&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233504636&cv=11&fst=1721233504636&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233520358&cv=11&fst=1721233520358&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233536358&cv=11&fst=1721233536358&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233551741&cv=11&fst=1721233551741&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233567557&cv=11&fst=1721233567557&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233585895&cv=11&fst=1721233585895&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233603684&cv=11&fst=1721233603684&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233621037&cv=11&fst=1721233621037&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233637910&cv=11&fst=1721233637910&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233655072&cv=11&fst=1721233655072&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233672759&cv=11&fst=1721233672759&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://googleads.g.doubleclick.net/pagead/viewthroughconversion/802362550/?random=1721233688537&cv=11&fst=1721233688537&bg=ffffff&guid=ON&async=1>m=45be47f0v9168344807za200zb78601642&gcd=13l3l3l3l1&dma=0&tcfd=1000g&tag_exp=0&u_w=800&u_h=600&url=https%3',
      url_host: 'googleads.g.doubleclick.net',
      url_sld: 'doubleclick.net',
      initiator_url: 'https://www.googletagmanager.com/gtag/js?id=AW-802362550:138:397',
      initiator_url_sld: 'googletagmanager.com',
      initiator_url_host: 'www.googletagmanager.com',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: 'contactad-EQxDhcVP',
      initiator_vendor_name: 'contact-address-test',
    },
    {
      url: 'https://js.hsadspixel.net/fb.js',
      url_host: 'js.hsadspixel.net',
      url_sld: 'hsadspixel.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:0:1365',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hsadspixel.net/fb.js',
      url_host: 'js.hsadspixel.net',
      url_sld: 'hsadspixel.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:2:281',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hsadspixel.net/fb.js',
      url_host: 'js.hsadspixel.net',
      url_sld: 'hsadspixel.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:8:281',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-analytics.net/analytics/1721233200000/7560723.js',
      url_host: 'js.hs-analytics.net',
      url_sld: 'hs-analytics.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:0:1822',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-analytics.net/analytics/1721233200000/7560723.js',
      url_host: 'js.hs-analytics.net',
      url_sld: 'hs-analytics.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:4:251',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-analytics.net/analytics/1721233500000/7560723.js',
      url_host: 'js.hs-analytics.net',
      url_sld: 'hs-analytics.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:10:251',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-analytics.net/analytics/1721233500000/7560723.js',
      url_host: 'js.hs-analytics.net',
      url_sld: 'hs-analytics.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:3:251',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-banner.com/7560723.js',
      url_host: 'js.hs-banner.com',
      url_sld: 'hs-banner.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:0:2290',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-banner.com/7560723.js',
      url_host: 'js.hs-banner.com',
      url_sld: 'hs-banner.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:10:285',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-banner.com/7560723.js',
      url_host: 'js.hs-banner.com',
      url_sld: 'hs-banner.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:7:285',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-banner.com/7560723.js',
      url_host: 'js.hs-banner.com',
      url_sld: 'hs-banner.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:8:285',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-banner.com/cookie-banner-public/v1/activity/click',
      url_host: 'js.hs-banner.com',
      url_sld: 'hs-banner.com',
      initiator_url: 'https://js.hs-banner.com/7560723.js:14:51153',
      initiator_url_sld: 'hs-banner.com',
      initiator_url_host: 'js.hs-banner.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hs-banner.com/cookie-banner-public/v1/activity/view',
      url_host: 'js.hs-banner.com',
      url_sld: 'hs-banner.com',
      initiator_url: 'https://js.hs-banner.com/7560723.js:14:51153',
      initiator_url_sld: 'hs-banner.com',
      initiator_url_host: 'js.hs-banner.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hsleadflows.net/leadflows.js',
      url_host: 'js.hsleadflows.net',
      url_sld: 'hsleadflows.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:0:849',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hsleadflows.net/leadflows.js',
      url_host: 'js.hsleadflows.net',
      url_sld: 'hsleadflows.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:11:289',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hsleadflows.net/leadflows.js',
      url_host: 'js.hsleadflows.net',
      url_sld: 'hsleadflows.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:3:289',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hsleadflows.net/leadflows.js',
      url_host: 'js.hsleadflows.net',
      url_sld: 'hsleadflows.net',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:9:289',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hubspotfeedback.com/feedbackweb-new.js',
      url_host: 'js.hubspotfeedback.com',
      url_sld: 'hubspotfeedback.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:0:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hubspotfeedback.com/feedbackweb-new.js',
      url_host: 'js.hubspotfeedback.com',
      url_sld: 'hubspotfeedback.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:11:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hubspotfeedback.com/feedbackweb-new.js',
      url_host: 'js.hubspotfeedback.com',
      url_sld: 'hubspotfeedback.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:5:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.hubspotfeedback.com/feedbackweb-new.js',
      url_host: 'js.hubspotfeedback.com',
      url_sld: 'hubspotfeedback.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:9:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.usemessages.com/conversations-embed.js',
      url_host: 'js.usemessages.com',
      url_sld: 'usemessages.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:0:2796',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.usemessages.com/conversations-embed.js',
      url_host: 'js.usemessages.com',
      url_sld: 'usemessages.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:10:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.usemessages.com/conversations-embed.js',
      url_host: 'js.usemessages.com',
      url_sld: 'usemessages.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:11:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://js.usemessages.com/conversations-embed.js',
      url_host: 'js.usemessages.com',
      url_sld: 'usemessages.com',
      initiator_url: 'https://www.didomi.io/hs/scriptloader/7560723.js:2:299',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://lookerstudio.google.com/embed/batchedDataV2?appVersion=20240715_0700',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getImageV2?reportId=2afb4e49-ba77-47d9-999e-bf6ea187d945&id=ADpgoFV5Z68%2FTDi4uwNt9UWJUqN5C0WJAD6%2Fru18zFCnvwM1w%2BosPjm1L0ugCIPteYPZm%2F1shkC6cKHxdijHjGx8PeJyQbeZrJ%2BVaM8wgfgOZz7A9qxpsv%2BLsQTPlfDN63YB5iNKYnps1WCLm',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getImageV2?reportId=2afb4e49-ba77-47d9-999e-bf6ea187d945&id=ADpgoFXqMGoF%2BXvxDjpYzFwPQuFa5ERSZZTMZkSjarq3%2BsFYPeHHRpnx3PT50xrF5Pimg3pivwZtkXvYpmzmKY%2Fmqg8ESSLnDLgMW8rdCjNzNv8KKflVn1y9yCFUZBcwisCGCznd8A1fuhOqRmCIaoU',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getImageV2?reportId=2afb4e49-ba77-47d9-999e-bf6ea187d945&id=ADpgoFXWOWjkKylkGZChwpjs3W%2BcMGMc%2FzS72KHvJxCt9llaQCRr5v7LQO3z6a1RMxeef1K8sGRcMLTZ8CXgBJ1nydCo9Mtu8kZk6Bhus2OWbkv4C5sSaTGNvWgQJtLquZruUGodNIyk13z7cBy12SOfP',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getImageV2?reportId=4afafd6b-c3b9-424f-969d-9935733fd26e&id=ADpgoFV2YQRSZxCqSjnKpKMXlPd8teoy99tTU%2FiJjGzbZsHV4bdMdrmxUTDsw0gz8rOcdz1O08wRepaCEHTNNJelg6%2FHYrSeipd%2FoPd9oMIoDCy0t6wpqWPYmv7JXYJ%2B3y602YlF6uP5XyVCLXz4H',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getImageV2?reportId=4afafd6b-c3b9-424f-969d-9935733fd26e&id=ADpgoFWsA899XYI5%2FJM3j5%2F73ispgruwtiU%2B8%2Fs0sZ5%2B1EmynSUWZQc1PV%2F8OVTQjT2FP3GrxsoCGSEDUx9Ok0kjSJBrDakbHlexhA5fBcagXqDS68K3P%2FO8KcTV4F2af83IEIF8eWnlT6%',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getImageV2?reportId=4afafd6b-c3b9-424f-969d-9935733fd26e&id=ADpgoFXCKuVrGRi68VWpiljgQPZkZtwnBaHA1YggVa9O0QVE2KmbJdZZEf0QQuukAHKLIFL1LT0FKXONfXih02RvZGVI%2BXAcMZ7zMLpH2svyDuoEGKQ4wznTPHE93SSelKkGj1VryrzCYKvUF%2BhMPQ%2B',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getReport?appVersion=20240715_0700',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/getSchema?appVersion=20240715_0700',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:2266:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://lookerstudio.google.com/embed/reporting/4afafd6b-c3b9-424f-969d-9935733fd26e/page/p_zzw0e9j0ed',
      url_host: 'lookerstudio.google.com',
      url_sld: 'google.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:2272:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAng4TaCSbwmEwKIdn9M0m9ZHpzjAkbMw8&v=3&callback=loadMapsApiCallback&language=en-US®ion=US&libraries=visualization',
      url_host: 'maps.googleapis.com',
      url_sld: 'maps.googleapis.com',
      initiator_url:
        'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=0/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=sye,syd,syf,sy13,sy14,syc,sy1a,sy1b,sy1c,syo,sy9,sy7,syx,sy2,syq,syp,sy18,sya,sy1d,sy8,sy17,syb,sy1e,sy1f,sy11,sy',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://maps.googleapis.com/maps/api/mapsjs/gen_204?csp_test=true',
      url_host: 'maps.googleapis.com',
      url_sld: 'maps.googleapis.com',
      initiator_url: 'https://www.gstatic.com/_/datastudio/_/js/k=datastudio.datastudio.en_US.wD235F7gVCc.2018.O/am=AAQ/d=1/rs=AHAPuoO8m4I-QTJHwAxeBstFG5_AcGRwQg/m=pm_base:350:70',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'www.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/0a363e70-5b82-41c7-a1bb-dadccf3e2a50.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/about:2419:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/2329f15c-7ba0-40b8-923c-26037e55f55d.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/es/:1083:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/2c46c31d-f08f-44fa-b1e8-6de35bba20bc.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/es/:2315:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:1088:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/about:1123:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:1110:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/affiliate-program:1105:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/client-stories:1137:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform:1112:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:1104:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:1075:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:1105:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:1080:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:1078:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:1080:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/partner-program:1107:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:1099:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/privacy-request:1113:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/referral-program:1108:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/resources:1088:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/367fea90-be96-440c-8386-e14b0d6d7de4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:1080:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/387fc604-f812-463b-ba2f-d0e51ac7cb1a.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:1974:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/47070ba7-c5bd-4ad4-95ea-74da87e1d61b.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/fr/:1099:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/50d3fbc6-1785-4669-9f19-965ac2f38d89.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/privacy-request:1998:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/5150a549-ce12-48c3-a488-ffaaa8f7eec7.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform:1991:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/5150a549-ce12-48c3-a488-ffaaa8f7eec7.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:1979:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/a93793d9-e505-4a42-94b7-00a2c3ac097b.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/client-stories:2000:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/aeeb8c46-51ef-4ad5-91af-a07599e14091.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/fr/:2331:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/b3e42be8-a551-4c49-8b38-dc617fbc941e.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/es/:1087:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/bb679475-7baa-44db-91d3-ac6d27857c34.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/affiliate-program:1951:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/c8245a09-3428-40ec-b66e-714cb4c45d6e.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:1987:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/ca060ae7-8a7d-4656-be6e-dd6ea470df3a.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:1949:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:1084:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/about:1119:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:1106:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/affiliate-program:1101:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/client-stories:1133:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform:1108:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:1100:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:1071:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:1101:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:1076:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:1074:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:1076:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/partner-program:1103:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:1095:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/privacy-request:1109:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/referral-program:1104:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/resources:1084:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/cf701828-184e-4d94-bc39-b30be9d89565.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:1076:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/e3fa0710-5940-433e-85eb-0c21dc48ea36.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:2316:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/e3fa0710-5940-433e-85eb-0c21dc48ea36.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/client-stories:2147:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/e3fa0710-5940-433e-85eb-0c21dc48ea36.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:2572:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/f197176b-a504-40a1-8e05-271b96e44c33.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:1909:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/f2a924fa-4ec2-4bdc-8381-5ba3fcd2e5b8.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/fr/:1103:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/fce82293-691f-4c3a-ae32-f8b46e5ccfe4.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/referral-program:1954:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://no-cache.hubspot.com/cta/default/7560723/fed750da-4e08-442a-9097-e6fa0a0ebc2e.png',
      url_host: 'no-cache.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform:2896:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/es/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/about:3451:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3476:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:3444:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/fr/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3565:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/partner-program:3775:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3700:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/privacy-request:3655:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/referral-program:3893:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/resources:3501:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3395:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-json-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18406',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/es/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/about:3451:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/client-stories:4048:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3476:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:3444:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/fr/:3615:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3565:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/partner-program:3775:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3700:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/privacy-request:3655:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/referral-program:3893:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/resources:3501:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3395:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-render-success&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18406',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/about:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/affiliate-program:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/client-stories:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/es/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/fr/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/partner-program:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/privacy-request:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/referral-program:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/resources:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://perf.hsforms.com/embed/v3/counters.gif?key=cta-with-analytics&value=1',
      url_host: 'perf.hsforms.com',
      url_sld: 'hsforms.com',
      initiator_url: 'https://www.didomi.io/hs/cta/cta/current.js:0:18406',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/api/event',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://plausible.io/js/plausible.js:0:824',
      initiator_url_sld: 'plausible.io',
      initiator_url_host: 'plausible.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/about:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/affiliate-program:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/client-stories:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/didomi-academy:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/es/:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/fr/:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/partner-program:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/preference-management-platform:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/privacy-request:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/referral-program:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/resources:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://plausible.io/js/plausible.js',
      url_host: 'plausible.io',
      url_sld: 'plausible.io',
      initiator_url: 'https://www.didomi.io/us-webinar-series:102:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/:92:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/about:119:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:106:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/affiliate-program:106:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/client-stories:138:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/consent-management-platform:108:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:106:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:84:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/didomi-academy:106:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/es/:86:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/fr/:107:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:86:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:80:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:86:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/partner-program:108:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/preference-management-platform:95:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/privacy-request:107:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/referral-program:109:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/resources:90:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://www.didomi.io/us-webinar-series:108:266',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://sdk.privacy-center.org/sdk/7ea75979dd0619cab93831f2e530a097d26c2dfd/modern/sdk.7ea75979dd0619cab93831f2e530a097d26c2dfd.js',
      url_host: 'sdk.privacy-center.org',
      url_sld: 'privacy-center.org',
      initiator_url: 'https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=www.didomi.io:0:130309',
      initiator_url_sld: 'privacy-center.org',
      initiator_url_host: 'sdk.privacy-center.org',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://service-reviews-ultimate.elfsight.com/data/reviews?uris%5B%5D=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fdidomi%2Freviews&with_text_only=1&min_rating=4&page_length=100&order=date',
      url_host: 'service-reviews-ultimate.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/apps/g2crowd-reviews/stable/b14844927d36ceb2233e3ccb69aceaa295649d90/app/g2CrowdReviews.js:64:599388',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://service-reviews-ultimate.elfsight.com/data/sources?uris%5B%5D=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fdidomi%2Freviews',
      url_host: 'service-reviews-ultimate.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/apps/g2crowd-reviews/stable/b14844927d36ceb2233e3ccb69aceaa295649d90/app/g2CrowdReviews.js:64:599388',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://ssl.gstatic.com/datastudio/cloud-lego-fe.fe-server_20240715.07_p0/css/css.css?cb=652656760',
      url_host: 'ssl.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded:5:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://ssl.gstatic.com/datastudio/cloud-lego-fe.fe-server_20240715.07_p0/css/material_theme.css?cb=652656760',
      url_host: 'ssl.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url: 'https://lookerstudio.google.com/embed/reporting/2afb4e49-ba77-47d9-999e-bf6ea187d945/page/p_lkda9ifded:5:0',
      initiator_url_sld: 'google.com',
      initiator_url_host: 'lookerstudio.google.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://ssl.gstatic.com/datastudio/cloud-lego-fe.fe-server_20240715.07_p0/static/datastudio_sprite1.svg',
      url_host: 'ssl.gstatic.com',
      url_sld: 'gstatic.com',
      initiator_url: 'https://ssl.gstatic.com/datastudio/cloud-lego-fe.fe-server_20240715.07_p0/css/css.css?cb=652656760:undefined:0',
      initiator_url_sld: 'gstatic.com',
      initiator_url_host: 'ssl.gstatic.com',
      type: 'http_redirect',
      vendor_id: 'google',
      vendor_name: 'Google Advertising Products',
      initiator_vendor_id: 'google',
      initiator_vendor_name: 'Google Advertising Products',
    },
    {
      url: 'https://static.elfsight.com/apps/g2crowd-reviews/stable/b14844927d36ceb2233e3ccb69aceaa295649d90/app/g2CrowdReviews.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://static.elfsight.com/platform/platform.js:7:13373',
      initiator_url_sld: 'elfsight.com',
      initiator_url_host: 'static.elfsight.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/about:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/affiliate-program:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/client-stories:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/didomi-academy:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/es/:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/partner-program:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/privacy-request:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/referral-program:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/resources:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.elfsight.com/platform/platform.js',
      url_host: 'static.elfsight.com',
      url_sld: 'elfsight.com',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3430:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/about:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/advanced-compliance-monitoring:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/affiliate-program:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/client-stories:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/consent-management-platform:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/consent-management-platform/connected-tv:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/consent-rate-benchmark:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/didomi-academy:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/es/:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/fr/:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/industry/banking-finance:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/industry/healthcare-pharma:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/industry/media-publishers:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/partner-program:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/preference-management-platform:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/privacy-request:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/referral-program:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/resources:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/content-cwv-embed/static-1.971/embed.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://www.didomi.io/us-webinar-series:3464:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://static.hsappstatic.net/feedback-web-renderer-ui/static-1.20378/bundles/fetcher.js',
      url_host: 'static.hsappstatic.net',
      url_sld: 'hsappstatic.net',
      initiator_url: 'https://app.hubspot.com/feedback-web-fetcher:0:0',
      initiator_url_sld: 'hubspot.com',
      initiator_url_host: 'app.hubspot.com',
      type: 'http_redirect',
      vendor_id: null,
      vendor_name: null,
      initiator_vendor_id: 'revcontent',
      initiator_vendor_name: 'Revcontent, LLC',
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347446&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347449&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347452&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347454&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347456&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347460&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347466&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347468&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347540&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347546&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347585&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347586&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347588&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347590&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347594&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233347597&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233359478&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372316&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372318&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372319&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372320&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372325&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372358&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372359&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372361&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
    {
      url: 'https://track.hubspot.com/__pto.gif?w=1721233372363&m=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)&j=1.1&n=TypeError&x=TypeError%3A+Cannot+read+properties+of+undefined+(reading+%270%27)%0A++++at+Navigator.get+(%3Canonymous%3E%3A9%3A5',
      url_host: 'track.hubspot.com',
      url_sld: 'hubspot.com',
      initiator_url: 'https://www.didomi.io/:0:0',
      initiator_url_sld: 'didomi.io',
      initiator_url_host: 'www.didomi.io',
      type: 'http_redirect',
      vendor_id: 'revcontent',
      vendor_name: 'Revcontent, LLC',
      initiator_vendor_id: null,
      initiator_vendor_name: null,
    },
  ],
  created_at: '2024-07-17T16:20:16.322Z',
  updated_at: '2024-07-17T16:29:03.806Z',
};
