import React from 'react';
import { DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { tw } from '@didomi/ui-foundation';
import { useMediaQuery, useSPAAssetsUrl } from '@didomi/helpers-react/hooks';
import { useGTM } from '@didomi/utility-react';

type ReportCriteriaCardProps = {
  id: string;
  passed: boolean;
  passedText: string;
  failedText: string;
  passedInformation: string;
  failedInformation: string;
  isLoading?: boolean;
};

/**
 * Component for showing the details of report criteria
 * The component will display a valid/invalid message and display the corresponding
 * message and information tooltip according to the result.
 *
 * @component
 * @param {ReportCriteriaCardProps} props
 * @example
 * const passedCriteria = true
 * return (
 *   <ReportCriteriaCard
 *      isLoading={isLoading}
 *      passed={passedCriteria}
 *      passedText="Your privacy policy is easy to find"
 *      failedText="Your privacy policy is hard to find"
 *      passedInformation="There is a link to your privacy policy on all pages of your website."
 *      failedInformation="Some pages of your website do not have a link to your privacy policy."
 *   />
 * )
 */
export const ReportCriteriaCard = ({ id, passed, passedText, failedText, passedInformation, failedInformation, isLoading }: ReportCriteriaCardProps) => {
  const { pushEventToDataLayer } = useGTM();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/compliance-report');
  const isSmallLayout = useMediaQuery('(max-width: 768px)');

  if (isSmallLayout) {
    return (
      <div data-skeleton={isLoading} className="w-full border-1 border-solid border-neutral-gray-3 rounded-lg p-xs flex">
        {passed ? (
          <div className="relative mb-s">
            <DidomiIcon className="absolute top-0 left-0" name="success-small" />
            <img className="h-[40px] min-w-[40px]" src={`${ASSETS_URL}/assets/images/messages/${id}.svg`} alt="" />
          </div>
        ) : (
          <div className="relative mb-s">
            <DidomiIcon className="absolute top-0 left-0" name="failure-small" />
            <img className="h-[40px] min-w-[40px]" src={`${ASSETS_URL}/assets/images/messages/${id}.svg`} alt="" />
          </div>
        )}
        <div className="flex flex-col mt-xxs ml-xs">
          <div className="h4 font-sans -mt-2 mb-2">{passed ? passedText : failedText}</div>
          <div className="text-body-small font-sans font-normal">{passed ? passedInformation : failedInformation}</div>
        </div>
      </div>
    );
  }

  return (
    <DidomiTooltip
      attachTo="report-content"
      trigger={isLoading ? 'manual' : 'click'}
      contentElementId={`report-criteria-tooltip-${id}-${passed ? 'success' : 'failure'}`}
      placement="top"
      variant="helper"
      onClick={() => pushEventToDataLayer('self-service-dashboard-criteria', { product: 'self-service', selection_value: passed ? passedText : failedText })}
    >
      <div
        data-tracking="self-service-dashboard-criteria"
        className={tw('h-full shadow-report-card pl-xs py-xs pr-s rounded-lg flex items-center min-h-[80px] min-w-report-criteria-card group-card ', {
          'cursor-pointer hover:shadow-card': !isLoading,
        })}
      >
        <div className="bg-neutral-gray-1 flex justify-center items-center mr-xs -ml-xs w-[40px]" style={{ height: 'calc(100% + 32px)' }}>
          <DidomiIcon data-skeleton={isLoading} name={passed ? 'success-small' : 'failure-small'} />
        </div>
        <div data-testid={'criteria-result-' + id} className="flex-1 text-body-normal text-primary-blue-6" data-skeleton={isLoading}>
          {isLoading ? 'Generic size text for covering the area' : passed ? passedText : failedText}
        </div>
        <div className="hidden" data-testid={'report-criteria-tooltip-' + id + '-success'} id={'report-criteria-tooltip-' + id + '-success'}>
          <div className="flex flex-col items-center justify-center text-primary-blue-6">
            <div className="relative mb-s">
              <DidomiIcon className="absolute -top-xxxs -left-xxxs" name="success" />
              <img src={`${ASSETS_URL}/assets/images/messages/${id}.svg`} alt="" />
            </div>
            <div className="h4 font-sans -mt-2 mb-2">{passedText}</div>
            <div className="text-body-small font-sans font-normal">{passedInformation}</div>
          </div>
        </div>
        <div className="hidden" data-testid={'report-criteria-tooltip-' + id + '-failure'} id={'report-criteria-tooltip-' + id + '-failure'}>
          <div className="flex flex-col items-center justify-center text-primary-blue-6">
            <div className="relative mb-s">
              <DidomiIcon className="absolute -top-xxxs -left-xxxs" name="failure" />
              <img src={`${ASSETS_URL}/assets/images/messages/${id}.svg`} alt="" />
            </div>
            <div className="h4 font-sans -mt-2 mb-2">{failedText}</div>
            <div className="text-body-small font-sans font-normal">{failedInformation}</div>
          </div>
        </div>
        <DidomiIcon data-testid="criteria-card-icon" data-skeleton={isLoading} className="ml-xs mt-xxxs cursor-pointer group-card-hover:text-primary-blue-4" name="preview" />
      </div>
    </DidomiTooltip>
  );
};
