import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { useBaseName, usePropertyLastReport } from '@hooks';
import { ComplianceReportErrorPage, ComplianceReportPage, LoadingComplianceReportPage } from '@pages';
import { ReportStatus } from '@types';

const App = () => {
  const basename = useBaseName();
  const { data: latestComplianceReport, isLoading: loadingLatestComplianceReport, refetch } = usePropertyLastReport();

  if (loadingLatestComplianceReport) {
    return (
      <div data-testid="loading-app" className="w-full h-full flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  // This function ensures the user is routed to the correct page based on the application state (orgId, isLoading, error, etc)
  // All child pages only need to check their own requirements (reported loaded, etc.).
  // If requirements are not satisfied, redirect to base and this function will handle the rest
  const getBaseRouteElement = () => {
    if (latestComplianceReport?.status === ReportStatus.ANALYZED) {
      return <ComplianceReportPage />;
    }

    if (latestComplianceReport?.status === ReportStatus.FAILURE) {
      return <ComplianceReportErrorPage refetchLastComplianceReport={refetch} />;
    }

    return <LoadingComplianceReportPage refetchLastComplianceReport={refetch} />;
  };

  return (
    <Routes>
      <Route path={`${basename}/dashboard`} element={getBaseRouteElement()}></Route>
      <Route path="*" element={<Navigate to={`${basename}/dashboard`} />} />
    </Routes>
  );
};

export { App };
