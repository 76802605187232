import { AgnostikReport, ComplianceReport, Privacy, Property, Request, Tracker } from '@types';

class ComplianceReportAdapter {
  constructor(private readonly complianceReportLegacy: AgnostikReport) {
    this.complianceReportLegacy = complianceReportLegacy;
  }

  private getDetails(): Record<string, any> {
    return this.complianceReportLegacy?.details_json;
  }

  private getProperty(): Property {
    const rawProperty = this.getDetails()?.property;

    return {
      id: rawProperty?.id,
      name: rawProperty?.name,
      website: rawProperty?.website,
    };
  }

  private getPrivacy(): Privacy {
    const rawPrivacy = this.getDetails()?.property?.privacy;
    const rawPrivacyUserConsent = rawPrivacy?.user_consent;
    const rawPrivacyPrivacyPolicy = rawPrivacy?.privacy_policy;

    return {
      has_user_consent_information: rawPrivacyUserConsent?.information || false,
      has_user_consent_choice: rawPrivacyUserConsent?.choice || false,
      has_short_cookies_lifetime: rawPrivacyUserConsent?.short_cookies_lifetime || false,
      has_privacy_policy: rawPrivacyPrivacyPolicy?.present || false,
      has_privacy_policy_all_pages: rawPrivacyPrivacyPolicy?.present_on_all_pages || false,
      has_privacy_policy_opt_out: rawPrivacyPrivacyPolicy?.optout || false,
    };
  }

  private getTrackers(): Tracker[] {
    const trackers = this.getDetails()?.property?.cookies || [];

    return trackers.map(tracker => ({
      name: tracker.name,
      type: tracker.type,
      is_third_party: tracker.is_third_party,
      max_lifetime_seconds: tracker.max_lifetime_seconds,
      violates_expiration_rule: tracker.violates_expiration_rule,
      page_url: tracker.page_url,
      host: tracker.host,
      sld: tracker.sld,
      vendor_id: tracker.vendor_id,
      vendor_name: tracker.vendor_name,
      initiator_vendor_id: tracker.initiator_vendor_id,
      initiator_vendor_name: tracker.initiator_vendor_name,
      initiator_url_host: tracker.initiator_url_host,
      initiator_url_sld: tracker.initiator_url_sld,
    }));
  }

  private getRequests(): Request[] {
    const requests = this.getDetails()?.requests || [];

    return requests.map(request => ({
      url: request.url,
      url_host: request.url_host,
      url_sld: request.url_sld,
      vendor_id: request.vendor_id,
      vendor_name: request.vendor_name,
      initiator_url: request.initiator_url,
      initiator_url_host: request.initiator_url_host,
      initiator_url_sld: request.initiator_url_sld,
      initiator_vendor_id: request.initiator_vendor_id,
      initiator_vendor_name: request.initiator_vendor_name,
      type: request.type,
    }));
  }

  private getRoundedScore(): number {
    const floatScore = this.complianceReportLegacy?.score || 0;

    return Math.ceil(floatScore);
  }

  toObject(): ComplianceReport {
    if (!this.complianceReportLegacy) {
      return null;
    }

    const { id, organization_id, property_id, status, created: created_at, modified: updated_at } = this.complianceReportLegacy;

    const score = this.getRoundedScore();
    const property = this.getProperty();
    const privacy = this.getPrivacy();
    const trackers = this.getTrackers();
    const requests = this.getRequests();

    return {
      id,
      organization_id,
      property_id,
      status,
      score,
      property,
      privacy,
      trackers,
      requests,
      created_at,
      updated_at,
    };
  }
}

/**
 * Takes an Agnostik Report (Legacy Compliance Report format served by the GCP CR API)
 * and returns an adapted Compliance Report.
 */
export const convertFromLegacyComplianceReport = (legacyComplianceReport: AgnostikReport): ComplianceReport => new ComplianceReportAdapter(legacyComplianceReport).toObject();
