import { useAuthToken } from '@didomi/utility-react';
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';
import { AgnostikReport } from '@types';
import { useOrganizationProperty } from '@hooks';

/**
 * Gets the last report for the currently active organization
 */
export const usePropertyLastReport = (options?: UseQueryOptions<AgnostikReport>) => {
  const token = useAuthToken();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { data: organizationProperty } = useOrganizationProperty();

  const fetchReports = async () => {
    if (organizationProperty?.id) {
      const response = await axiosWithInterceptors.get<{ data: AgnostikReport[] }>(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/compliances`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          organization_id: organizationProperty?.organization_id,
          property_id: organizationProperty?.id,
          $with: ['details'],
          $limit: 1,
          $sort: {
            created: -1,
          },
        },
      });

      if (response?.data?.data) {
        return response.data.data[0];
      }
    }

    return null;
  };

  return useQuery<AgnostikReport, AxiosError>(
    [
      'agnostik-reports',
      {
        property_id: organizationProperty?.id,
        organization_id: organizationProperty?.organization_id,
      },
    ],
    fetchReports,
    options,
  );
};
