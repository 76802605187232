import React, { useMemo } from 'react';
import {
  DidomiCollapsibleTabHeader,
  DidomiCollapsibleTabHeaders,
  DidomiCollapsibleTabHeading,
  DidomiCollapsibleTabPanel,
  DidomiCollapsibleTabPanels,
  DidomiCollapsibleTabs,
} from '@didomi/ui-atoms-react';
import { useGTM } from '@didomi/utility-react';
import { ReportItemsTags, ReportItemsThirdPartyCookies, ReportsItemsFirstPartyCookies } from '@components';
import { ComplianceReport, Tracker } from '@types';
import { deduplicateArrayByObjectKeys } from '@utils';

type ReportItemsFoundProps = {
  complianceReport: ComplianceReport;
  isLoading?: boolean;
};

/**
 * Component for showing the tabs that include all of the report items
 *
 * @component
 * @param {ReportCriteriaCardProps} props
 * @example
 * return (
 *   <ReportItemsFound complianceReport={complianceReport} />
 * )
 */
export const ReportItemsFound = ({ complianceReport, isLoading }: ReportItemsFoundProps) => {
  const { pushEventToDataLayer } = useGTM();
  const { firstPartyTrackersUnique, thirdPartyTrackersUnique, firstPartyTrackersIssues, thirdPartyTrackersIssues, requestsUnique } = useMemo(() => {
    if (!complianceReport) {
      return {
        firstPartyTrackersUnique: [],
        thirdPartyTrackersUnique: [],
        requestsUnique: [],
        firstPartyTrackersIssues: 0,
        thirdPartyTrackersIssues: 0,
      };
    }

    const firstPartyTrackers = complianceReport?.trackers.filter((tracker: Tracker) => !tracker.is_third_party) || [];
    // deduplicate the first party trackers
    const firstPartyTrackersUnique = deduplicateArrayByObjectKeys(firstPartyTrackers, [
      'name',
      'page_url',
      'vendor_name',
      'sld',
      'max_lifetime_seconds',
      'violates_expiration_rule',
    ]);
    const firstPartyTrackersIssues = firstPartyTrackers?.filter((tracker: Tracker) => tracker.violates_expiration_rule)?.length || 0;

    let thirdPartyTrackers = complianceReport?.trackers.filter((tracker: Tracker) => tracker.is_third_party) || [];
    // deduplicate the third party trackers
    const thirdPartyTrackersUnique = deduplicateArrayByObjectKeys(thirdPartyTrackers, [
      'name',
      'page_url',
      'vendor_name',
      'sld',
      'max_lifetime_seconds',
      'violates_expiration_rule',
    ]);

    const thirdPartyTrackersIssues = thirdPartyTrackers?.filter((tracker: Tracker) => tracker.violates_expiration_rule)?.length || 0;

    const requests = complianceReport?.requests || [];
    // deduplicate the requests
    const requestsUnique = deduplicateArrayByObjectKeys(requests, ['url', 'type', 'vendor_name', 'initiator_vendor_name']);

    return {
      firstPartyTrackersUnique,
      thirdPartyTrackersUnique,
      requestsUnique,
      firstPartyTrackersIssues,
      thirdPartyTrackersIssues,
    };
  }, [complianceReport]);

  const TABS_NAMES = {
    0: '1st party cookies',
    1: '3rd party cookies',
    2: 'Tags',
  };

  const handleActiveTabChange = (event: CustomEvent<number> | number) => {
    const tabId = event instanceof CustomEvent ? event.detail : event;
    pushEventToDataLayer('self-service-dashboard-tables', {
      product: 'self-service',
      selection_value: TABS_NAMES[tabId],
    });
  };

  return (
    <div className={isLoading ? 'bg-white p-s' : ''}>
      <h2 className="h2 mt-l mb-s">Items found</h2>
      <DidomiCollapsibleTabs data-testid="collapsible-tabs" data-skeleton={isLoading} onActiveTabChange={handleActiveTabChange}>
        <DidomiCollapsibleTabHeading>
          <DidomiCollapsibleTabHeaders>
            <DidomiCollapsibleTabHeader
              data-tracking="self-service-dashboard-tables"
              tabTitle={`${firstPartyTrackersUnique.length}`}
              tabDescription="1st party cookies"
              tabInfo={`${firstPartyTrackersIssues} issues`}
            ></DidomiCollapsibleTabHeader>
            <DidomiCollapsibleTabHeader
              data-tracking="self-service-dashboard-tables"
              tabTitle={`${thirdPartyTrackersUnique?.length}`}
              tabDescription="3rd party cookies"
              tabInfo={`${thirdPartyTrackersIssues} issues`}
            ></DidomiCollapsibleTabHeader>
            <DidomiCollapsibleTabHeader
              data-tracking="self-service-dashboard-tables"
              tabTitle={requestsUnique?.length?.toString()}
              tabDescription="Tags"
            ></DidomiCollapsibleTabHeader>
          </DidomiCollapsibleTabHeaders>
        </DidomiCollapsibleTabHeading>
        <DidomiCollapsibleTabPanels>
          <DidomiCollapsibleTabPanel data-skeleton={isLoading}>
            <ReportsItemsFirstPartyCookies trackers={firstPartyTrackersUnique} />
          </DidomiCollapsibleTabPanel>
          <DidomiCollapsibleTabPanel data-skeleton={isLoading}>
            <ReportItemsThirdPartyCookies trackers={thirdPartyTrackersUnique} />
          </DidomiCollapsibleTabPanel>

          <DidomiCollapsibleTabPanel data-skeleton={isLoading}>
            <ReportItemsTags requests={requestsUnique} />
          </DidomiCollapsibleTabPanel>
        </DidomiCollapsibleTabPanels>
      </DidomiCollapsibleTabs>
    </div>
  );
};
